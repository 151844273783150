// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { AppellationVarietyRel } from './../appellationvarietyrel.model';
import { AppellationVarietyRelService } from './../services/appellationvarietyrel.service';
import { Appellation } from './../../appellation/appellation.model';
import { AppellationService } from './../../appellation/services/appellation.service';
import { Variety } from './../../variety/variety.model';
import { VarietyService } from './../../variety/services/variety.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-appellationvarietyrel-form',
  templateUrl: './appellationvarietyrel-form.component.html',
  styleUrls: ['./appellationvarietyrel-form.component.css']
})
export class AppellationVarietyRelFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'AppellationVarietyRel Form';
  public appellationvarietyrel: AppellationVarietyRel;
  public form: FormGroup;
  public ids;

  // Appellation Select
  public appellationsData: ListItem[];
  public selectedAppellationId: number;

  // Variety Select
  public varietysData: ListItem[];
  public selectedVarietyId: number;

  constructor(
    public _appellationService: AppellationService,
    public _varietyService: VarietyService,
    public _appellationvarietyrelService: AppellationVarietyRelService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();
    this.fetchAppellations();
    this.fetchVarietys();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  fetchAppellations = () => {
    this._appellationService.getAllItems().subscribe(
      (data: ListItem[]) => this.appellationsData = data,
      error => console.error(error));
  }

  fetchVarietys = () => {
    this._varietyService.getAllItems().subscribe(
      (data: ListItem[]) => this.varietysData = data,
      error => console.error(error));
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (appellationvarietyrel?: AppellationVarietyRel) => {
    return {
      id: [{
          value: (appellationvarietyrel ? appellationvarietyrel.id : ''),
          disabled: true
      }],      appellationId: [
        (appellationvarietyrel ? appellationvarietyrel.appellationId : ''),
        Validators.required
      ],
      varietyId: [
        (appellationvarietyrel ? appellationvarietyrel.varietyId : ''),
        Validators.required
      ],
      percentage: [
        (appellationvarietyrel ? appellationvarietyrel.percentage : ''),
        Validators.required
      ]
    };
  }


  load = () => {
    this._appellationvarietyrelService.getSingle(this.ids).subscribe(
      (appellationvarietyrel: AppellationVarietyRel) => {
        this.appellationvarietyrel = {
          id: appellationvarietyrel.id,
          appellationId: appellationvarietyrel.appellationId,
          varietyId: appellationvarietyrel.varietyId,
          percentage: appellationvarietyrel.percentage
		};
        this.form = this._formBuilder.group(this.getNewForm(this.appellationvarietyrel));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a appellationvarietyrel, we are adding a appellationvarietyrel
    if (!this.appellationvarietyrel) {
      this.add();
    } else { // If we didn't get a appellationvarietyrel, we are adding a appellationvarietyrel
      this.update();
    }
  }

  add = () => {
    this._appellationvarietyrelService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'AppellationVarietyRel added successfuly');
        this._router.navigate(['./appellationvarietyrel-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._appellationvarietyrelService.update(<AppellationVarietyRel>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'AppellationVarietyRel edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.appellationvarietyrel.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `appellationvarietyrel: ${this.appellationvarietyrel.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.appellationvarietyrel.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._appellationvarietyrelService.delete(id).subscribe(
      result => {
        this._router.navigate(['./appellationvarietyrel-table']);

        this._notificationService.success(
          'Deleted',
          `The appellationvarietyrel entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
