// Modules imports
import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Language } from '../../entities/language/language.model';
import { LanguageService } from '../../entities/language/services/language.service';

import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { GenericService } from '../../services/generic.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';


export interface TableRow<R extends BaseL> extends Base {
  i18n: R[];
}

export interface Base {
  id: number;
  name: string;
  description?: string;
}

export interface BaseL extends Base {
  languageId: number;
}

@Injectable()
export abstract class CommonFormComponent<T extends Base, T18 extends BaseL> {

  foreignKeyFieldName = "";
  listRoute = "";
  displayedColumnsDef = [];

  ELEMENT_DATA: TableRow<T18>[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public form: FormGroup;

  public extraFields = [];

  // Language Select
  public languages: Language[];
  public languagesMap = new Map<number, Language>();
  public languagesTabMap = new Map<number, Language>();

  public flagMap = new Map([
    ['IT', 'IT'],
    ['EN', 'GB'],
    ['FR', 'FR'],
    ['DE', 'DE']
  ]);


  public selectedRowIndex = -1;
  public selectedRow: TableRow<T18>;
  public selectedI18n: T18;
  public selectedLanguageId;

  constructor(
    public _languageService: LanguageService,
    public _genericService: GenericService<T>,
    public _genericT18Service: GenericService<T18>,
    public _notificationService: NotificationService,
    public _formBuilder: FormBuilder,
    public _router: Router,
    public _dialog: MatDialog) { }

  getTableRow(item: T): any {
    throw new Error('Method not implemented.');
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
  }

  getColumnsIds() {
    return this.displayedColumnsDef.map((item) => item["columnDef"]);
  }

  getNewForm = () => {
    return {
      name: ['', Validators.required],
      description: ['']
    };
  }

  fetchDataAndLanguages() {

    forkJoin([this._languageService.getAll()]).subscribe(([languages]) => {
      this.setLanguages(languages);
      this._genericService.getAll().subscribe((data: T[]) => {
        this.ELEMENT_DATA = data.map((item) => this.getTableRow(item));
        console.log("ELEMENT_DATA", this.ELEMENT_DATA);
        this.dataSource.data = this.ELEMENT_DATA;
        if (this.ELEMENT_DATA.length > 0) {
          this.changeSelectedRow({ id: 1 });
        }
      });
    });
  }

  changeLanguage(event) {
    this.selectedLanguageId = event.value;
    this.changeFormValues();
  }

  setLanguages = (data: Language[]) => {
    this.languages = data.filter((item) => this.flagMap.get(item.isoCode) != undefined);
    this.languages.forEach((item) => {
      item["flag"] = this.flagMap.get(item.isoCode);
      this.languagesMap.set(item.id, item);
    });
    let counter = 1;
    this.flagMap.forEach((value, key) => {
      this.languagesTabMap.set(counter++, this.languages.find((item) => item.isoCode == key));
    });
  }

  changeSelectedRow(row) {
    this.selectedI18n = undefined;
    this.initForm();
    this.form.controls.rest
    this.selectedRowIndex = row.id;
    this.selectedRow = row; //this.ELEMENT_DATA.find((item) => item.id == row.id);
    //console.log("this.ELEMENT_DATA", this.ELEMENT_DATA);
    //console.log("row", row);
    if (!this.selectedRow) return;
    this.changeFormValues();
  }

  changeFormValues(): void {
    if (!this.selectedRow || !this.selectedRow.i18n) return;
    this.selectedI18n = this.selectedRow.i18n.find(element => element.languageId == this.selectedLanguageId);
    //console.log("selectedI18n", this.selectedI18n);
    if (this.selectedI18n) {
      this.form.controls.name.setValue(this.selectedI18n.name);
      this.form.controls.description.setValue(this.selectedI18n.description);
    } else {
      this.form.controls.name.setValue(undefined);
      this.form.controls.description.setValue(undefined);
    }
    this.changeFormValuesExtraFields();
  }

  changeFormValuesExtraFields(): void {
  }

  save = () => {
    if (!this.selectedI18n) this.add();
    else this.update();
  }

  add = () => {

    const newRecordI18n = <T18>this.form.getRawValue();
    newRecordI18n.languageId =  this.selectedLanguageId;
    newRecordI18n[this.foreignKeyFieldName] = this.selectedRow.id; 

    this._genericT18Service.add(newRecordI18n).subscribe(
      result => {
        this._notificationService.success('Success', 'Record added successfuly');
        this.selectedRow.i18n.push(result);
        this.selectedI18n = result;
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {

    const formValues = <T18>this.form.getRawValue();
    let merged = { ...this.selectedI18n, ...formValues };

    this._genericT18Service.update(merged, [merged.id] as any).subscribe(
      result => {
        this._notificationService.success('Success', 'Record edited successfuly');
        this.selectedI18n = result;
        const index = this.selectedRow.i18n.findIndex((item) => item.id == this.selectedI18n.id );
        this.selectedRow.i18n[index] = result;
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteI18nConfirmation = () => {
    if (this.selectedI18n.id) {
      const dialogRef = this._dialog.open(DeleteDialogComponent, {
        data: `${this.foreignKeyFieldName}: ${this.selectedI18n.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.selectedI18n.id);
          this.initForm();
        }
      });
    }
  }
  
  deleteConfirmation = () => {
    if (this.selectedRow) {
      const dialogRef = this._dialog.open(DeleteDialogComponent, {
        data: `${this.foreignKeyFieldName}: ${this.selectedI18n.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.selectedI18n.id);
          this.initForm();
        }
      });
    }
  }

  deleteBadge = (id): void => {
    this._genericT18Service.delete(id).subscribe(
      result => {
        this.selectedRow.i18n = this.selectedRow.i18n.filter(item => item !== this.selectedI18n)
        this.selectedI18n = undefined;
        this._notificationService.success(
          'Deleted',
          `The entry with the id ='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  public navigationList = [
		// { title: 'Appellation', routerLink: 'appellation' },
		{ title: 'Award', routerLink: 'award' },
		{ title: 'Appellation', routerLink: 'appellation' },
		{ title: 'Certification', routerLink: 'certification' },
		{ title: 'Colour', routerLink: 'colour' },
		{ title: 'Country', routerLink: 'country' },
		{ title: 'Dryness', routerLink: 'dryness' },
		{ title: 'Effervescence', routerLink: 'effervescence' },
		{ title: 'Region', routerLink: 'region' },
		{ title: 'Variety', routerLink: 'variety' },
	];

	btnClick = function (link) {
		//this._router.navigateByUrl(link + "-list");
		this._router.navigateByUrl(link + "-table");
	};

	// btnClickI18n = function (link) {
	// 	this._router.navigateByUrl(link + "I18n-list");
	// };

	btnClickViewForm = function (link) {
		this._router.navigateByUrl(link + "-with-i18n-form");
	};

  
  list= () => {
    this._router.navigate([this.listRoute]);
  }

}
