// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-31 ( Time 13:50:23 )

import { GenericService } from './../../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { WineOenologistRel } from '../wineoenologistrel.model';
import { Configuration } from '../../../app.configuration';

@Injectable()
export class WineOenologistRelService extends GenericService<WineOenologistRel>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'wineoenologistrel/');
    }

}
