// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-06-04 ( Time 17:00:42 )

// Modules imports
import { NotificationService } from '../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

import { PROFILE } from '../constants';

// Models and services imports
import { WineService } from '../entities/wine/services/wine.service';
import { ProducerService } from '../entities/producer/services/producer.service';
import { ColourService } from '../entities/colour/services/colour.service';
import { EffervescenceService } from '../entities/effervescence/services/effervescence.service';
import { DrynessService } from '../entities/dryness/services/dryness.service';
import { RegionService } from '../entities/region/services/region.service';
import { CountryService } from '../entities/country/services/country.service';
import { AppellationService } from '../entities/appellation/services/appellation.service';
import * as _ from 'underscore';
import { Account } from 'src/app/entities/account/account.model';
import { WineExt } from 'src/app/wine-view/wine-view.component';
import { ListItem } from 'src/app/services/generic.service';

import {
  PROFILE_ADMIN,
} from "./../constants/index.js";
import { Region } from '../entities/region/region.model';
import { values } from 'underscore';
import { VarietyService } from '../entities/variety/services/variety.service';
import { NamedQueryService } from '../services/named-query.service';
import { OenologistService } from '../entities/oenologist/services/oenologist.service';
import { AppellationVarietyRelService } from '../entities/appellationvarietyrel/services/appellationvarietyrel.service';
import { AppellationVarietyRel } from '../entities/appellationvarietyrel/appellationvarietyrel.model';
import { AppellationOenologistRel } from '../entities/appellationoenologistrel/appellationoenologistrel.model';
import { AppellationOenologistRelService } from '../entities/appellationoenologistrel/services/appellationoenologistrel.service';

@Component({
  selector: 'app-wine-entry-form',
  templateUrl: './wine-entry-form.component.html',
  styleUrls: ['./wine-entry-form.component.css']
})
export class WineEntryFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'Wine Form';
  public wine: WineExt;
  public form: FormGroup;
  public ids;

  readonly NONE_ITEM = { label: "None", value: undefined };

  // Producer Select
  public producersData: ListItem[];
  public selectedProducerId: number;

  // Colour Select
  public coloursData: ListItem[];
  public selectedColourId: number;

  // Effervescence Select
  public effervescencesData: ListItem[];
  public selectedEffervescenceId: number;

  // Dryness Select
  public drynesssData: ListItem[];
  public selectedDrynessId: number;

  // Region Select
  public regionsData: Region[];
  public contryRegionsData: ListItem[];
  public selectedRegionId: number;

  // Country Select
  public countrysData: ListItem[];
  public selectedCountryId: number;

  // Appellation Select
  public appellationsData: ListItem[];
  public selectedAppellationId: number;

  // Variety Select
  public varietiesData: ListItem[];
  public selectedVarietyIds: number[];

  // Variety Select
  public oenologistsData: ListItem[];
  public selectedOenologistId: number;

  public appellationVarietiesData: AppellationVarietyRel[];
  public appellationOenologistsData: AppellationOenologistRel[];

  //clonazione vino
  private cloning = false;

  years: number[] = [];
  currentYear = new Date().getFullYear();

  constructor(
    public _producerService: ProducerService,
    public _colourService: ColourService,
    public _effervescenceService: EffervescenceService,
    public _drynessService: DrynessService,
    public _regionService: RegionService,
    public _countryService: CountryService,
    public _appellationService: AppellationService,
    public _varietyService: VarietyService,
    public _oenologistService: OenologistService,
    public _wineService: WineService,
    public _nqService: NamedQueryService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    private _appellationVarietyRelService: AppellationVarietyRelService,
    private _appellationOenologistService: AppellationOenologistRelService,

    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();
    this.fetchProducers();
    this.fetchColours();
    this.fetchEffervescences();
    this.fetchDrynesss();
    this.fetchCountrys();
    this.fetchRegions();
    this.fetchAppellations();
    this.fetchVarieties();
    this.fetchOenologists();
    this.fetchAppellationVarieties();
    this.fetchAppellationOenologists();

    //this.years.push(this.NONE_ITEM);
    for (let year = this.currentYear; year >= 1800; year--) {
      this.years.push(year);
    }
    this.cloning = ("1" === localStorage.getItem("CLONING"));
    localStorage.removeItem("CLONING");
    this.initForm();

    if (!this.form.controls.vintage.value) this.form.controls.vintage.setValue(undefined);
  }

  fetchAppellationVarieties() {
    this._appellationVarietyRelService.getAll().subscribe((data) => {
      this.appellationVarietiesData = data;
    });
  }

  fetchAppellationOenologists() {
    this._appellationOenologistService.getAll().subscribe((data) => {
      this.appellationOenologistsData = data;
    });
  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
      this.ids = _.values(p);
    });
  }

  fetchProducers = () => {
    this._producerService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.producersData = data;
        this.producersData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchColours = () => {
    this._colourService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.coloursData = data;
        this.coloursData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchEffervescences = () => {
    this._effervescenceService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.effervescencesData = data;
        this.effervescencesData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchDrynesss = () => {
    this._drynessService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.drynesssData = data;
        this.drynesssData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchRegions = () => {
    this._regionService.getAll().subscribe(
      (data: Region[]) => {
        data.sort((a: Region, b: Region) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.regionsData = data;
        //console.log("regionsData", this.regionsData);
        this.contryRegionsData = [];
        this.contryRegionsData.push(this.NONE_ITEM);
        data.forEach(element => {
          this.contryRegionsData.push({ label: element.name, value: element.id });
        });
      },
      error => console.error(error));
  }

  fetchCountrys = () => {
    this._countryService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.countrysData = data;
        this.countrysData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  selectedCountry(countryId) {
    console.log(" ", countryId);
    this.contryRegionsData = [];
    this.contryRegionsData.push(this.NONE_ITEM);
    this.regionsData.forEach(element => {
      if (element.countryId == countryId)
        this.contryRegionsData.push({ label: element.name, value: element.id });
    });
  }

  selectedAppellation(appellationId) {
    const values = [];
    this.appellationVarietiesData.forEach((item) => {
      if (item.appellationId == appellationId) values.push(item.varietyId);
    });
    this.form.controls.varietyIds.setValue(values);

    const oenovalues = [];
    this.appellationOenologistsData.forEach((item) => {
      if (item.appellationId == appellationId) oenovalues.push(item.oenologistId);
    });
    this.form.controls.oenologistIds.setValue(oenovalues);
  }

  fetchAppellations = () => {
    this._appellationService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.appellationsData = data;
        this.appellationsData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchVarieties = () => {
    this._varietyService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.varietiesData = data;
        this.varietiesData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }

  fetchOenologists = () => {
    this._oenologistService.getAllItems().subscribe(
      (data: ListItem[]) => {
        this.oenologistsData = data;
        this.oenologistsData.unshift(this.NONE_ITEM);
      },
      error => console.error(error));
  }


  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (wine?: WineExt) => {
    return {
      id: [{
        value: (wine ? wine.id : ''),
        disabled: true
      }], name: [
        (wine ? wine.name : ''),
        Validators.required
      ],
      description: [
        (wine ? document.createTextNode(wine.description).textContent : '')
        // Create a DOM Text node:
        //var text_node = document.createTextNode( wine.description).textContent;
      ],
      producerId: [
        (wine ? wine.producerId : ''),
        Validators.required
      ],
      colourId: [
        (wine ? wine.colourId : '')
      ],
      effervescenceId: [
        (wine ? wine.effervescenceId : '')
      ],
      drynessId: [
        (wine ? wine.drynessId : '')
      ],
      fortified: [
        (wine ? wine.fortified : '')
      ],
      degree: [
        (wine ? wine.degree : ''),
        Validators.required
      ],
      regionId: [
        (wine ? wine.regionId : ''),
        Validators.required
      ],
      countryId: [
        (wine ? wine.countryId : ''),
        Validators.required
      ],
      appellationId: [
        (wine ? wine.appellationId : '')
      ],
      authorId: [
        (wine ? wine.authorId : ''),
      ],
      approved: [
        (wine ? wine.approved : '')
      ],
      vintage: [
        (wine ? wine.vintage : '')
      ],
      organic: [
        (wine ? wine.organic : '')
      ],
      varietyIds: [
        ('')
      ],
      oenologistIds: [
        ('')
      ]

    };
  }


  load = () => {
    this._wineService.getSingle(this.ids).subscribe(
      (wine: WineExt) => {
        this.wine = {
          id: wine.id,
          name: wine.name,
          description: wine.description,
          producerId: wine.producerId,
          colourId: wine.colourId,
          effervescenceId: wine.effervescenceId,
          drynessId: wine.drynessId,
          fortified: wine.fortified,
          degree: wine.degree,
          regionId: wine.regionId,
          countryId: wine.countryId,
          appellationId: wine.appellationId,
          authorId: wine.authorId,
          approved: wine.approved,
          organic: wine.organic,
          vintage: wine.vintage
        };
        if (this.cloning) this.wine.id = undefined;
        this.form = this._formBuilder.group(this.getNewForm(this.wine));

        setTimeout(() => this.selectedCountry(wine.countryId), 1000);

        //recupero le varietà associate al vino
        var observable = this._nqService.getNQArray(["wine", "varieties", wine.id]);
        observable.subscribe((data) => {
          this.form.controls.varietyIds.setValue(data);
        });

        //recupero gli oenologist associate al vino
        var observable = this._nqService.getNQArray(["wine", "oenologists", wine.id]);
        observable.subscribe((data) => {
          this.form.controls.oenologistIds.setValue(data);
        });

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
    // If we didn't get a wine, we are adding a wine
    if (!this.wine || !this.wine.id) {

      console.log("going to add")
      this.add();

    } else {

      // If we didn't get a wine, we are adding a wine
      console.log("going to update")
      this.update();

    }


  }

  add = () => {

    const account: Account = JSON.parse(localStorage.getItem(PROFILE));
    var wine: WineExt = this.form.getRawValue();
    wine.authorId = account.id;
    wine.approved = account.type == PROFILE_ADMIN ? true : false;
    console.log("wine", wine);

    this._wineService.add(wine).subscribe(
      result => {
        this._notificationService.success('Success', 'Wine record submitted for approval successfully');

        //aggiorno le varietà associate
        let varieties = { wineId: result.id, ids: this.form.controls.varietyIds.value };
        varieties.ids = varieties.ids.filter(element => element !== undefined);
        var observable = this._nqService.postNQSingle(varieties, ["wine", "varieties", "update"]);
        observable.subscribe((data) => {
          console.log("wine varieties update: esito", data);
        });

        //aggiorno gli oenologist  associate
        let oenologists = { wineId: result.id, ids: this.form.controls.oenologistIds.value };
        oenologists.ids = oenologists.ids.filter(element => element !== undefined);
        var observable = this._nqService.postNQSingle(oenologists, ["wine", "oenologists", "update"]);
        observable.subscribe((data) => {
          console.log("wine oenologists update: esito", data);
        });

        this._router.navigate(['./wine-entry-form', result.id]);
      },
      error => {
        1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });

  }

  update = () => {
    console.log("<WineExt>this.form.getRawValue()", <WineExt>this.form.getRawValue());
    this._wineService.update(<WineExt>this.form.getRawValue(), this.ids).subscribe(
      (result) => {
        this._notificationService.success('Success', 'Wine edited successfuly');

        //aggiorno le varietà associate
        let varieties = { wineId: result.id, ids: this.form.controls.varietyIds.value };
        varieties.ids = varieties.ids.filter(element => element !== undefined);
        var observable = this._nqService.postNQSingle(varieties, ["wine", "varieties", "update"]);
        observable.subscribe((data) => {
          console.log("wine varieties update: esito", data);
        });

        //aggiorno gli oenologist  associate
        let oenologists = { wineId: result.id, ids: this.form.controls.oenologistIds.value };
        oenologists.ids = oenologists.ids.filter(element => element !== undefined);
        var observable = this._nqService.postNQSingle(oenologists, ["wine", "oenologists", "update"]);
        observable.subscribe((data) => {
          console.log("wine oenologists update: esito", data);
        });

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.wine.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `wine: ${this.wine.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.wine.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._wineService.delete(id).subscribe(
      result => {
        this._router.navigate(['./wines-search-table']);

        this._notificationService.success(
          'Deleted',
          `The wine entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
