// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-09-12 ( Time 15:14:29 )

import { GenericService } from './../../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { WineVarietyRel } from '../winevarietyrel.model';
import { Configuration } from '../../../app.configuration';

@Injectable()
export class WineVarietyRelService extends GenericService<WineVarietyRel>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'winevarietyrel/');
    }

}
