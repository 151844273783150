<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-16 ( Time 17:31:23 ) -->


<div class="material-card-narrow mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border" style="position: relative">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>

  <div>
    <mat-form-field id="filter" appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter()" placeholder="type filter text (at least 3 chars)" [(ngModel)]="filterText" #input>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="clearFilter()">Clear Filter</button>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container *ngFor="let column of displayedColumnsDef" matColumnDef="{{column.columnDef}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="{{ column.class}">{{ column.header}}</th>
        <td mat-cell *matCellDef="let row" (dblclick)="viewItem(row)">
        <div class="table-data-content">
            <span> {{ column.cell(row) }}
            </span>
          </div>
      </td>
      </ng-container>

      <ng-container matColumnDef="getDetails">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="editItem(element)">
            <mat-icon>edit</mat-icon>
          </button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>

  <div class="mdl-card__menu">
    <button mat-raised-button color="primary" routerLink="../wlist-form" >
      <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>Add
    </button>
  </div>

</div>