<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-16 ( Time 17:31:23 ) -->

<div class="material-card-wide mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border" style="position: relative">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>

  <div *ngIf="!sqlMode" class="filterLine">
    <!-- <mat-slide-toggle [color]="green" [(ngModel)]="sqlMode" >
      SQL
    </mat-slide-toggle> -->

    <mat-form-field id="filter" appearance="outline">
      <mat-label>Filter line</mat-label>
      <input matInput (keyup)="applyFilter()" placeholder="type filter text (at least 3 chars)" [(ngModel)]="filterText" >
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="clearFilter()">Clear Filter</button>
    <button *ngIf="isSearchMode()" [disabled]="this.selectedVarieties.length == 0" mat-flat-button color="primary"
      (click)="searchByVarieties()">Search by
      Varieties</button>
    <mat-form-field id="varietiesSelect" appearance="outline" class="form-full-width">
      <mat-select multiple [(ngModel)]="selectedVarieties">
        <mat-option *ngFor="let variety of varieties" [value]="variety.value">{{variety.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    <!-- <div *ngIf="sqlMode" class="filterLine">
      <mat-slide-toggle [color]="green" [(ngModel)]="sqlMode" >
        SQL
      </mat-slide-toggle>
      <mat-form-field id="search" appearance="outline">
        <mat-label>SQL line</mat-label>
        <input matInput placeholder="type SQL expression" [(ngModel)]="searchText" #input>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="search()">Search</button>
    </div> -->

  <div class="mat-elevation-z8">

    <div *ngIf="spinner.loading">
      <div class="example-h2">Loading ...</div>
      <mat-spinner class="example-margin" [diameter]="spinner.diameter" [color]="spinner.color">
      </mat-spinner>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container *ngFor="let column of displayedColumnsDef" matColumnDef="{{column.columnDef}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header}}</th>
        <td mat-cell *matCellDef="let row" (dblclick)="viewItem(row)"><span *ngIf="!isBoolean(column)">{{
            column.cell(row) }}</span>
          <mat-checkbox disabled *ngIf="isBoolean(column)" [checked]="isTrue(row, column.columnDef)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewItem">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="viewItem(element)">
            <mat-icon>remove_red_eye</mat-icon>
          </button> </td>
      </ng-container>

      <ng-container matColumnDef="addToList">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="addToList(element)">
            <mat-icon>list</mat-icon>
          </button> </td>
      </ng-container>

      <ng-container matColumnDef="review">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="rate_review(element)">
            <mat-icon>rate_review</mat-icon>
          </button> </td>
      </ng-container>

      <ng-container matColumnDef="clone">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="cloneWine(element)">
            <mat-icon>control_point_duplicate</mat-icon>
          </button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{filterText}}"</td>
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>



  <div class="mdl-card__menu">
    <button mat-flat-button color="primary" routerLink="../wine-entry-form">
      <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>Add
    </button>
  </div>


</div>