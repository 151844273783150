import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AsyncSubject, Subject } from 'rxjs';
import { maxLength } from './maxlength.validator';

@Component({
  selector: "tiny-editor",
  styleUrls: ["./tiny-editor.component.css"],
  templateUrl: "./tiny-editor.component.html"
})
export class TinyEditorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TinyEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  private editorSubject: Subject<any> = new AsyncSubject();

  public myForm;

  ngOnInit(): void {
    this.myForm = new FormGroup({
      body: new FormControl(this.data.content, Validators.required, maxLength(this.editorSubject, this.data.maxLength))
    });
  }

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  public onSubmit() {
    this.dialogRef.close(this.myForm.controls.body.value)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
