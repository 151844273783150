<button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr; index as i" [id]="'star_'+i"
  (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
  <mat-icon>
    {{showIcon(i)}}
  </mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
<p class="body-2">
  Your rated <span class="body-2">{{rating}}</span> / <span class="body-2">{{starCount}}</span>
</p>