// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private auth: AuthService, public router: Router) { }
    
    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['signin']);
            return false;
        }
        return true;
    }
}