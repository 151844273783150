<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">Profile</h2>
  </div>

  <div class="add-edit-form">

    <mat-grid-list cols="1" rowHeight="80px">

      <form [formGroup]="form" novalidate>

        <mat-grid-tile>
          <mat-form-field class="form-full-width">
            <input type="text" matInput placeholder="account name" required formControlName="name">
            <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.name.errors.required">Name is required.</mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field class="form-full-width">
            <input type="text" matInput placeholder="account email" formControlName="email">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field class="form-full-width">
            <input type="text" matInput placeholder="account type" formControlName="type">
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!f.language.value">Language</mat-label>
            <mat-select required formControlName="language">
              <mat-option *ngFor="let language of languages" [value]="language.isoCode">
                {{language.englishName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="profile">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!f.pname.value">Profile Name</mat-label>
            <input matInput required formControlName="pname">
            <mat-error *ngIf="!f.pname.valid">
              field is required
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="profile">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!f.pdescription.value">Description</mat-label>
            <input matInput formControlName="pdescription">
            <mat-icon matSuffix (click)="initEditor('pdescription', 2147483647)">edit</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <button mat-raised-button color="primary" [disabled]="!(form.valid && form.dirty)" (click)="updateProfile()" type="submit">Update
            Profile</button>
        </mat-grid-tile>

      </form>

      <form [formGroup]="formp" novalidate>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!fp.oldpassword.value">Old password</mat-label>
            <input type="password" matInput required formControlName="oldpassword">
            <div *ngIf="fp.oldpassword.touched && fp.oldpassword.invalid" class="alert alert-danger">
              <mat-error *ngIf="fp.oldpassword.errors.required">
                Password is required.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!fp.password.value">New password</mat-label>
            <input type="password" matInput required formControlName="password">
            <div *ngIf="fp.password.touched && fp.password.invalid" class="alert alert-danger">
              <mat-error *ngIf="fp.password.errors.required">
                Password is required.
              </mat-error>
              <mat-error *ngIf="fP.password.errors.minlength">
                Password should be at least 8 character.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!fp.password1.value">Retype password</mat-label>
            <input type="password" matInput placeholder="password1" required formControlName="password1">
            <div *ngIf="fp.password1.touched && fp.password1.invalid" class="alert alert-danger">
              <mat-error *ngIf="fp.password1.errors.required">
                Password is required.
              </mat-error>
              <mat-error *ngIf="fp.password1.errors.minlength">
                Password should be at least 8 character.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <button mat-raised-button color="primary" [disabled]="!(formp.valid && formp.dirty)" (click)="updatePassword()" type="submit">Update
            Password</button>
        </mat-grid-tile>

      </form>
    </mat-grid-list>

  </div>

</div>