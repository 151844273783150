// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-02-23 ( Time 17:47:02 )

import { Injectable } from '@angular/core';
import { API_BASE_URL } from "./constants";

@Injectable()
export class Configuration {
    public server = API_BASE_URL;
    public apiUrl = '';
    public serverWithApiUrl = this.server + "/" + this.apiUrl;
}
