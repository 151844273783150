<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-16 ( Time 17:31:23 ) -->

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">

    <div class="mdl-card__title mdl-card--border" style="position: relative">
        <h2 class="mdl-card__title-text">Wine Details</h2>
    </div>

    <mat-card>
        <mat-card-header>
            <div mat-card-avatar></div>
            <mat-card-title>{{wine.name}}</mat-card-title>
            <mat-card-subtitle>{{wine.producer}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div [innerHtml]="wine.description"></div>
        </mat-card-content>
    </mat-card>

    <mat-grid-list cols="4" rowHeight="40px">

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Oenologist</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.oenologists}}</div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Country</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.country}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Region</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.region}}</div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Appellation</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.appellation}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Varieties</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.varieties}}</div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Effervescence</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.effervescence}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Colour</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.colour}}</div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Fortified</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.fortified}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Organic</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.organic}}</div>
        </mat-grid-tile>

        
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Dryness</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.dryness}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Degree</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.degree}}</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Vintage</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.vintage}}</div>
        </mat-grid-tile>


        <!-- <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-label>Rating</mat-label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <div>{{wine.rating}}</div>
        </mat-grid-tile> -->

    </mat-grid-list>


    <mat-card>
        <mat-card-header>
            <mat-card-title>Average rating is <b>{{avgRating}}/5</b> on <b>{{reviewCount}}</b> reviews
            </mat-card-title>
            <button mat-mini-fab (click)="reviewsList()">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </mat-card-header>
    </mat-card>


    <div class="mdl-card__menu">
        <button *ngIf="editable" mat-raised-button color="primary" (click)="editItem()">
            <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="approvable" mat-raised-button color="primary" (click)="approveItem()">
            <mat-icon>check_circle_outline</mat-icon>
        </button>
    </div>

</div>