// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:23 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { WineWlistRel } from './../winewlistrel.model';
import { WineWlistRelService } from './../services/winewlistrel.service';
import { Wlist } from './../../wlist/wlist.model';
import { WlistService } from './../../wlist/services/wlist.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-winewlistrel-form',
  templateUrl: './winewlistrel-form.component.html',
  styleUrls: ['./winewlistrel-form.component.css']
})
export class WineWlistRelFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'WineWlistRel Form';
  public winewlistrel: WineWlistRel;
  public form: FormGroup;
  public ids;

  // Wlist Select
  public wlistsData: ListItem[];
  public selectedWlistId: number;

  constructor(
    public _wlistService: WlistService,
    public _winewlistrelService: WineWlistRelService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();
    this.fetchWlists();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  fetchWlists = () => {
    this._wlistService.getAllItems().subscribe(
      (data: ListItem[]) => this.wlistsData = data,
      error => console.error(error));
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (winewlistrel?: WineWlistRel) => {
    return {
      id: [{
          value: (winewlistrel ? winewlistrel.id : ''),
          disabled: true
      }],      wineId: [
        (winewlistrel ? winewlistrel.wineId : ''),
        Validators.required
      ],
      wlistId: [
        (winewlistrel ? winewlistrel.wlistId : ''),
        Validators.required
      ]
    };
  }


  load = () => {
    this._winewlistrelService.getSingle(this.ids).subscribe(
      (winewlistrel: WineWlistRel) => {
        this.winewlistrel = {
          id: winewlistrel.id,
          wineId: winewlistrel.wineId,
          wlistId: winewlistrel.wlistId
		};
        this.form = this._formBuilder.group(this.getNewForm(this.winewlistrel));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a winewlistrel, we are adding a winewlistrel
    if (!this.winewlistrel) {
      this.add();
    } else { // If we didn't get a winewlistrel, we are adding a winewlistrel
      this.update();
    }
  }

  add = () => {
    this._winewlistrelService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'WineWlistRel added successfuly');
        this._router.navigate(['./winewlistrel-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._winewlistrelService.update(<WineWlistRel>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'WineWlistRel edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.winewlistrel.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `winewlistrel: ${this.winewlistrel.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.winewlistrel.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._winewlistrelService.delete(id).subscribe(
      result => {
        this._router.navigate(['./winewlistrel-table']);

        this._notificationService.success(
          'Deleted',
          `The winewlistrel entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
