// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
    ACCESS_TOKEN
} from "../constants";

@Injectable()
export class AuthService {

    jwtHelper: JwtHelperService = new JwtHelperService();

    public isAuthenticated(): boolean {

        // Get token from localstorage
        let token = localStorage.getItem(ACCESS_TOKEN);
        // Check if token is null or empty
        if (token) {
            // Check whether the token is expired and return
            // true or false
            return !this.jwtHelper.isTokenExpired(token);
        }
        else {
            return false
        }
    }
}