// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { Account } from './../account.model';
import { AccountService } from './../services/account.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css']
})
export class AccountFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'Account Form';
  public account: Account;
  public form: FormGroup;
  public ids;

  constructor(
    public _accountService: AccountService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (account?: Account) => {
    return {
      id: [{
          value: (account ? account.id : ''),
          disabled: true
      }],      type: [
        (account ? account.type : ''),
        Validators.required
      ],
      name: [
        (account ? account.name : ''),
        Validators.required
      ],
      email: [
        (account ? account.email : ''),
        Validators.required
      ],
      password: [
        (account ? account.password : '')
      ],
      imageUrl: [
        (account ? account.imageUrl : '')
      ],
      imageBase64: [
        (account ? account.imageBase64 : '')
      ],
      emailVerified: [
        (account ? account.emailVerified : '')
      ],
      enabled: [
        (account ? account.enabled : '')
      ],
      provider: [
        (account ? account.provider : '')
      ],
      providerId: [
        (account ? account.providerId : '')
      ],
      createdAt: [
        (account ? account.createdAt : '')
      ],
      updatedAt: [
        (account ? account.updatedAt : '')
      ],
      language: [
        (account ? account.language : ''),
        Validators.required
      ]
    };
  }


  load = () => {
    this._accountService.getSingle(this.ids).subscribe(
      (account: Account) => {
        this.account = {
          id: account.id,
          type: account.type,
          name: account.name,
          email: account.email,
          password: account.password,
          imageUrl: account.imageUrl,
          imageBase64: account.imageBase64,
          emailVerified: account.emailVerified,
          enabled: account.enabled,
          provider: account.provider,
          providerId: account.providerId,
          createdAt: account.createdAt,
          updatedAt: account.updatedAt,
          language: account.language
		};
        this.form = this._formBuilder.group(this.getNewForm(this.account));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a account, we are adding a account
    if (!this.account) {
      this.add();
    } else { // If we didn't get a account, we are adding a account
      this.update();
    }
  }

  add = () => {
    this._accountService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'Account added successfuly');
        this._router.navigate(['./account-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._accountService.update(<Account>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'Account edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.account.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `account: ${this.account.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.account.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._accountService.delete(id).subscribe(
      result => {
        this._router.navigate(['./account-table']);

        this._notificationService.success(
          'Deleted',
          `The account entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
