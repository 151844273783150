// Modules imports
import { Component } from '@angular/core';
import { WineExt } from '../wine-view/wine-view.component';
import { WinesTableComponent } from './wines-table.component';
import { CONTENT_MODE } from './wines-table.component';


@Component({
  selector: 'app-wines-records-table',
  templateUrl: './wines-table.component.html',
  styleUrls: ['./wines-table.component.css']
})
export class WinesRecordsTableComponent extends WinesTableComponent {

  // Producer Select
  title = "My Wines";
  contentMode = CONTENT_MODE.RECORDS;

  ngOnInit() {
    this.displayedColumnsDef.push({ columnDef: 'approved', header: 'Approved', cell: (row: WineExt) => `${row["approved"]}` });
    this.displayedColumns.push("approved");
    super.ngOnInit();
  }

}

