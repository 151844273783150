<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-17 ( Time 09:49:48 ) --> 

<div class="not-found-card-wide mdl-card mdl-shadow--2dp">
  <div class="mdl-card__title">
    <h2 class="mdl-card__title-text">404</h2>
  </div>
  <div class="mdl-card__supporting-text">
    Sorry. We couldn't find that page :(
  </div>
  <div class="mdl-card__actions mdl-card--border">
    <div class="buttonHolder">
      <a routerLink="" class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
        Go Home
      </a>
    </div>
  </div>
</div>