import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/services/notification.service";
import { confirmRegistration } from "src/app/util/APIUtils";
import * as _ from 'underscore';

@Component({
  selector: "app-confirm-registration",
  templateUrl: "./confirm-registration.component.html",
  styleUrls: ["./confirm-registration.component.css"],
})
export class ConfirmRegistrationComponent implements OnInit {

  account: Account;

  constructor(
    private _router: Router,
    public _route: ActivatedRoute,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this._route.params.subscribe((p) => {

      const token = _.values(p)[0];

      confirmRegistration(token)
        .then((response) => {
          this._notificationService.success(
            "Success",
            "Account has been confirmed!"
          );
          this._router.navigate(["signin"]);
        })
        .catch((error) => {
          console.log(
            (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
          );
          this._notificationService.error("Error", error.message);
        });
    });
  }

}

