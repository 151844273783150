import {
    AuthGuardService as AuthGuard
} from './services/auth-guard.service';

import {
    RoleGuardService as RoleGuard
} from './services/role-guard.service';

import { ProfileComponent } from './profile/profile.component';
import { VarietySynonymTableComponent } from './synonym-table/variety-synonym-table.component';
import { AppellationVarietyTableComponent } from './appellation-variety-table/appellation-variety-table.component';
import { AppellationOenologistTableComponent } from './appellation-oenologist-table/appellation-oenologist-table.component';


//Signin + register
import { SigninComponent } from './signin/signin.component';
import { RegisterComponent } from './register/register.component';

//Dictionary views
import { AwardWithI18nComponent } from './dict-list/award-with-i18n/award-with-i18n.component';
import { AppellationWithI18nComponent } from './dict-list/appellation-with-i18n/appellation-with-i18n.component';
import { CertificationWithI18nComponent } from './dict-list/certification-with-i18n/certification-with-i18n.component';
import { ColourWithI18nComponent } from './dict-list/colour-with-i18n/colour-with-i18n.component';
import { CountryWithI18nComponent } from './dict-list/country-with-i18n/country-with-i18n.component';
import { DrynessWithI18nComponent } from './dict-list/dryness-with-i18n/dryness-with-i18n.component';
import { RegionWithI18nComponent } from './dict-list/region-with-i18n/region-with-i18n.component';
import { VarietyWithI18nComponent } from './dict-list/variety-with-i18n/variety-with-i18n.component';

import { WinesSearchTableComponent } from './wines-table/wines-search-table.component';
import { WineListPageComponent } from './wine-list-page/wine-list-page.component';
import { WineViewComponent } from './wine-view/wine-view.component';
import { WinesRecordsTableComponent } from './wines-table/wines-records-table.component';
import { WinesProposalsTableComponent } from './wines-table/wines-proposals-table.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProducerTableComponent } from './entities/producer/producer-table/producer-table.component';
import { VendorTableComponent } from './entities/vendor/vendor-table/vendor-table.component';
import { EffervescenceWithI18nComponent } from './dict-list/effervescence-with-i18n/effervescence-with-i18n.component';
import { ConfirmRegistrationComponent } from './reset-password/confirm-registration/confirm-registration.component';
import { WineEntryFormComponent } from './wine-entry-form/wine-entry-form.component';
import { ReviewEntryFormComponent } from './review/review-entry-form/review-entry-form.component';
import { ReviewListComponent } from './review/review-list/review-list.component';

export const customAppRoutings = [

    { path: 'signin', component: SigninComponent },
    {
        path: 'register', component: RegisterComponent, canActivate: [RoleGuard], data: {
            expectedRole: 'A'
        }
    },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'variety-synonym-table', component: VarietySynonymTableComponent, canActivate: [AuthGuard] },
    { path: 'appellation-variety-table', component: AppellationVarietyTableComponent, canActivate: [AuthGuard] },
    { path: 'appellation-oenologist-table', component: AppellationOenologistTableComponent, canActivate: [AuthGuard] },

    //Dictionary I18n views
    { path: 'award-with-i18n-form', component: AwardWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'appellation-with-i18n-form', component: AppellationWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'certification-with-i18n-form', component: CertificationWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'colour-with-i18n-form', component: ColourWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'country-with-i18n-form', component: CountryWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'dryness-with-i18n-form', component: DrynessWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'effervescence-with-i18n-form', component: EffervescenceWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'region-with-i18n-form', component: RegionWithI18nComponent, canActivate: [AuthGuard] },
    { path: 'variety-with-i18n-form', component: VarietyWithI18nComponent, canActivate: [AuthGuard] },

    //Main Pages
    { path: 'wines-search-table', component: WinesSearchTableComponent, canActivate: [AuthGuard] },
    { path: 'wines-records-table', component: WinesRecordsTableComponent, canActivate: [AuthGuard] },
    { path: 'wines-proposals-table', component: WinesProposalsTableComponent, canActivate: [AuthGuard] },

    { path: 'wine-view/:id', component: WineViewComponent, canActivate: [AuthGuard] },
    { path: 'wine-entry-form', component: WineEntryFormComponent, canActivate: [AuthGuard] },
    { path: 'wine-entry-form/:id', component: WineEntryFormComponent, canActivate: [AuthGuard] },

    { path: 'review-entry-form', component: ReviewEntryFormComponent, canActivate: [AuthGuard] },
    { path: 'review-entry-form/:id', component: ReviewEntryFormComponent, canActivate: [AuthGuard] },

    { path: 'review-list/:id', component: ReviewListComponent, canActivate: [AuthGuard] },

    { path: 'producer-table', component: ProducerTableComponent, canActivate: [AuthGuard] },
    { path: 'vendor-table', component: VendorTableComponent, canActivate: [AuthGuard] },

    { path: 'wine-list-page', component: WineListPageComponent, canActivate: [AuthGuard] },

    { path: 'reset-password/:token', component: ResetPasswordComponent },

    { path: 'confirm-registration/:token', component: ConfirmRegistrationComponent }
    
];
