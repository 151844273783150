
<!-- File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-31 ( Time 13:46:32 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">


      <mat-grid-list cols="2" rowHeight="80px">
	



        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.name .value ">name</mat-label>
            <input matInput type="text" required  formControlName="name">
	
	            <mat-error *ngIf="!form.controls.name.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	



        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.description .value ">description</mat-label>
            <input matInput type="text"    formControlName="description">
	            <mat-icon matSuffix (click)="initEditor('description', 2147483647)">edit</mat-icon>
	
	          </mat-form-field>
        </mat-grid-tile>	

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.producerId .value ">producerId</mat-label>
	            <mat-error *ngIf="form.controls.producerId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="producerId" name="producer" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let producer of producersData" [value]="producer.value">
	              {{producer.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.colourId .value ">colourId</mat-label>
	          <mat-select formControlName="colourId" name="colour"   [compareWith]="compareObjects" >
            <mat-option *ngFor="let colour of coloursData" [value]="colour.value">
	              {{colour.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.effervescenceId .value ">effervescenceId</mat-label>
	          <mat-select formControlName="effervescenceId" name="effervescence"   [compareWith]="compareObjects" >
            <mat-option *ngFor="let effervescence of effervescencesData" [value]="effervescence.value">
	              {{effervescence.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.drynessId .value ">drynessId</mat-label>
	          <mat-select formControlName="drynessId" name="dryness"   [compareWith]="compareObjects" >
            <mat-option *ngFor="let dryness of drynesssData" [value]="dryness.value">
	              {{dryness.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>



        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-checkbox formControlName="fortified"   color="primary">Fortified</mat-checkbox>
        </mat-grid-tile>



        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.degree .value ">degree</mat-label>
            <input matInput type="number" required  formControlName="degree">
	
	            <mat-error *ngIf="!form.controls.degree.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.regionId .value ">regionId</mat-label>
	            <mat-error *ngIf="form.controls.regionId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="regionId" name="region" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let region of regionsData" [value]="region.value">
	              {{region.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.countryId .value ">countryId</mat-label>
	            <mat-error *ngIf="form.controls.countryId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="countryId" name="country" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let country of countrysData" [value]="country.value">
	              {{country.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.appellationId .value ">appellationId</mat-label>
	          <mat-select formControlName="appellationId" name="appellation"   [compareWith]="compareObjects" >
            <mat-option *ngFor="let appellation of appellationsData" [value]="appellation.value">
	              {{appellation.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.authorId .value ">authorId</mat-label>
	            <mat-error *ngIf="form.controls.authorId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="authorId" name="account" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let account of accountsData" [value]="account.value">
	              {{account.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>



        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-checkbox formControlName="approved"   color="primary">Approved</mat-checkbox>
        </mat-grid-tile>



        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.vintage .value ">vintage</mat-label>
            <input matInput type="number"    formControlName="vintage">
	
	          </mat-form-field>
        </mat-grid-tile>	



        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-checkbox formControlName="organic"   color="primary">Organic</mat-checkbox>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!wine?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>