
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { Review } from '../review.model';
import { ReviewService } from '../services/review.service';



@Component({
  selector: 'app-review-table',
  templateUrl: './review-table.component.html',
  styleUrls: ['./review-table.component.css']
})
export class ReviewTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "Review Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: Review) => `${row.id ? row.id : ""}` },
    { columnDef: 'wineId', header: 'WineId', cell: (row: Review) => `${row.wineId ? row.wineId : ""}` },
    { columnDef: 'authorId', header: 'AuthorId', cell: (row: Review) => `${row.authorId ? row.authorId : ""}` },
    { columnDef: 'languageId', header: 'LanguageId', cell: (row: Review) => `${row.languageId ? row.languageId : ""}` },
    { columnDef: 'rating', header: 'Rating', cell: (row: Review) => `${row.rating ? row.rating : ""}` },
    { columnDef: 'title', header: 'Title', cell: (row: Review) => `${row.title ? row.title : ""}` },
    { columnDef: 'content', header: 'Content', cell: (row: Review) => `${row.content ? row.content : ""}` },
    { columnDef: 'sideNotes', header: 'SideNotes', cell: (row: Review) => `${row.sideNotes ? row.sideNotes : ""}` },
    { columnDef: 'createdAt', header: 'CreatedAt', cell: (row: Review) => `${row.createdAt ? row.createdAt : ""}` },
  ];

displayedColumns = [
  "id",
  "wineId",
  "authorId",
  "languageId",
  "rating",
  "title",
  "content",
  "sideNotes",
  "createdAt",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _reviewService: ReviewService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._reviewService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["review-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["review-form", row.id]);
  }



}
