<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-16 ( Time 17:31:23 ) -->

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">

    <div class="mdl-card__title mdl-card--border" style="position: relative">
        <h2 class="mdl-card__title-text">Wine Reviews</h2>
    </div>

    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Average rating is <b>{{avgRating}}/5</b> on <b>{{reviewCount}}</b> reviews</mat-card-title>
            <mat-card-subtitle>{{wine.name}} - {{wine.producer}}</mat-card-subtitle>
        </mat-card-header>
        <!-- <mat-card-content>
            <div [innerHtml]="wine.description"></div>
        </mat-card-content> -->
    </mat-card>

    <mat-card *ngFor="let review of reviews" class="example-card">
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-subtitle><b>Author:</b> {{review.username}} - <b>Rating {{review.rating}}/5</b>
            </mat-card-subtitle>
            <mat-card-title>Title: {{review.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div [innerHtml]="review.content"></div>
            <b>Side Notes</b>
            <div [innerHtml]="review.sideNotes"></div>
        </mat-card-content>
    </mat-card>

</div>