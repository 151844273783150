// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-02-23 ( Time 17:47:02 )

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EmitterService } from "./services/emitter.service";
import {
  Notification,
  LoginNotification,
  ProfileNotification,
} from "./services/notification.model";

import {
  PROFILE_ADMIN,
  PROFILE_USER,
  PROFILE_PROVIDER,
  PROFILE_VENDOR,
  PROFILE,
  ACCESS_TOKEN
} from "./constants/index.js";

import { NotificationService } from "./services/notification.service";
import { logout, validateToken, getProfile } from "./util/APIUtils";
import { Observable, of, BehaviorSubject } from "rxjs";
import { AuthService } from "./services/auth.service";
import { reduce } from "underscore";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title: String = "Oeno";
  profile$: Observable<any>;
  profileSource = new BehaviorSubject<any>(undefined);

  menuListHeaderGlobal = [
    { label: "Wine Search", routerLink: "wines-search-table", icon: "search", ifLogged: true, ifProfile: undefined },
  ];
  menuListHeader = [];

  menuListWinesGlobal = [
    { label: "My Wines", routerLink: "wines-records-table", icon: "library_books", ifLogged: true, ifProfile: undefined },
    { label: "Wine Proposals", routerLink: "wines-proposals-table", icon: "library_add", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Wine Lists", routerLink: "wine-list-page", icon: "format_list_bulleted", ifLogged: true, ifProfile: undefined  },
  ];
  menuListWines = [];

  menuListUtilsGlobal = [
    { label: "Dictionaries", routerLink: "award-with-i18n-form", icon: "sort_by_alpha", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Variety <-> Synonyms", routerLink: "variety-synonym-table", icon: "add_link", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Appellation <-> Varieties", routerLink: "appellation-variety-table", icon: "add_link", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Appellation <-> Oenologists", routerLink: "appellation-oenologist-table", icon: "add_link", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    // { label: "Database", routerLink: "assets/dbdoc/database.html", icon: "storage", ifLogged: true, ifProfile: [PROFILE_ADMIN] }
  ];
  menuListUtils = [];

  menuListActorsGlobal = [
    { label: "Producers", routerLink: "producer-table", icon: "local_drink", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Vendors", routerLink: "vendor-table", icon: "storefront", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Oenologists", routerLink: "oenologist-table", icon: "liquor", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
  ];
  menuListActors = [];

  menuListAccountGlobal = [
    { label: "Login", routerLink: "signin", icon: "login", ifLogged: false },
    { label: "New Account", routerLink: "register", icon: "app_registration", ifLogged: true, ifProfile: [PROFILE_ADMIN] },
    { label: "Profile", routerLink: "profile", icon: "account_circle", ifLogged: true }
  ];
  menuListAccount = [];

  hrefMenuListGlobal = [
    { label: "Database", routerLink: "assets/dbdoc/database.html", icon: "inventory", ifLogged: true, ifProfile: [PROFILE_ADMIN] }
  ];
  hrefMenuList = [];

  logged = false;
  profileType = "U";

  public optionsForNotifications = {
    position: ["middle", "right"],
    timeOut: 5000,
    lastOnBottom: true,
    showProgressBar: false,
    pauseOnHover: false,
    clickToClose: true,
    preventDuplicates: true,
  };

  constructor(
    private _router: Router,
    private _notificationService: NotificationService,
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this.profile$ = this.profileSource.asObservable();

    this._notificationService.init();

    EmitterService.get(NotificationService.LOGIN).subscribe(
      (notif: LoginNotification) => this.updateLoggedStatus(notif)
    );

    EmitterService.get(NotificationService.PROFILE).subscribe(
      (notif: ProfileNotification) => this.updateProfile()
    );

    if (localStorage.getItem(ACCESS_TOKEN)) {
      validateToken()
        .then((response) => {
          if (!response.success) {
            console.log("Rimosso ACCESS_TOKEN dalla cache", response.message);

            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(PROFILE);
            this._notificationService.loggedOut();
            this.logged = false;
            this.profileSource.next(undefined);
          } else {
            console.log("ACCESS_TOKEN is still valid: ", response.message);
            this.logged = true;
            getProfile().then((response) => {
              // console.log("getProfile", response.data);
              this.profileType = "profile_" + response.data.type;
              const profile = JSON.stringify(response.data);
              // console.log("profile", profile);
              localStorage.setItem(PROFILE, profile);
              this._notificationService.updateProfile();
              this._notificationService.loggedIn();
            });
          }
        })
        .catch((error) => {
          this.profileSource.next(undefined);
          this.logged = false;
          console.log(
            "Errore sulla chiamata " +
            error +
            " --> Rimosso ACCESS_TOKEN dalla cache:"
          );
          localStorage.removeItem(PROFILE);
          localStorage.removeItem(ACCESS_TOKEN);
          this._notificationService.loggedOut();
        });
    } else {
      this._notificationService.loggedOut();
    }


  }

  updateLoggedStatus(notif: LoginNotification) {
    // console.log("notif", notif);
    this.logged = notif.logged;
    const userProfileType = this.profileSource.getValue();
    this.menuListHeader = this.menuListHeaderGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
    this.menuListUtils = this.menuListUtilsGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
    this.menuListWines = this.menuListWinesGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
    this.menuListActors = this.menuListActorsGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
    this.menuListAccount = this.menuListAccountGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
    this.hrefMenuList = this.hrefMenuListGlobal.filter(
      (item) => (item.ifLogged === undefined || (item.ifLogged && this.logged) || (!item.ifLogged && !this.logged))
        && (item.ifProfile === undefined || (item.ifProfile && item.ifProfile.includes(userProfileType.type)))
    );
  }

  updateProfile() {
    // console.log("notif", "updateProfile");
    const profile = JSON.parse(localStorage.getItem(PROFILE));
    this.profileType = "profile_" + profile.type;
    this.profileSource.next(profile);
  }

  logout() {
    this.logged = false;
    this.profileSource.next(undefined);
    this._notificationService.loggedOut();

    if (!this._authService.isAuthenticated()) {

      console.log("Token is expired");
      localStorage.removeItem(ACCESS_TOKEN);
      this._router.navigate(['signin']);
      return;
    }

    logout()
      .then((response) => {
        localStorage.removeItem(ACCESS_TOKEN);
        this._notificationService.success(
          "Success",
          "You're successfully logged out!"
        );
        console.log("You're successfully logged out!");
        this._router.navigate(["signin"]);
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
        );
      });
  }
}
