// Modules imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PROFILE } from "../constants";
import { Account } from '../entities/account/account.model';
import { AppellationService } from '../entities/appellation/services/appellation.service';
import { ColourService } from '../entities/colour/services/colour.service';
import { CountryService } from '../entities/country/services/country.service';
import { DrynessService } from '../entities/dryness/services/dryness.service';
import { EffervescenceService } from '../entities/effervescence/services/effervescence.service';
import { ProducerService } from '../entities/producer/services/producer.service';
import { RegionService } from '../entities/region/services/region.service';
import { ReviewService } from '../entities/review/services/review.service';
import { WineService } from '../entities/wine/services/wine.service';
import { NotificationService } from '../services/notification.service';
import { NQWlist, NamedQueryService } from '../services/named-query.service';
import { SelectListDialogComponent } from '../wine-list-page/select-list-dialog/select-list-dialog.component';
import { WineExt } from '../wine-view/wine-view.component';
import { ListItem } from '../services/generic.service';
import { VarietyService } from '../entities/variety/services/variety.service';
import { OenologistService } from '../entities/oenologist/services/oenologist.service';
import { SearchByVarietiesDialogComponent } from '../search-by-varieties-dialog/search-by-varieties-dialog.component';

export enum CONTENT_MODE {
  SEARCH,
  RECORDS,
  PROPOSAL
}

@Component({
  selector: 'app-wines-search-table',
  templateUrl: './wines-table.component.html',
  styleUrls: ['./wines-table.component.css']
})
export class WinesTableComponent implements OnInit {

  // Producer Select
  public producersData = new Map<number, string>();
  public regionsData = new Map<number, string>();
  public countrysData = new Map<number, string>();
  public coloursData = new Map<number, string>();
  public effervescencesData = new Map<number, string>();
  public drynessData = new Map<number, string>();
  public appellationData = new Map<number, string>();
  public varietyData = new Map<string, string>();
  public oenologistData = new Map<string, string>();

  public filterText: string = null;
  public selectedVarieties: any = [];
  varieties: ListItem[];
 

  sqlMode: boolean = false;

  public searchText: string = null;

  title = "Wine Search";
  contentMode: CONTENT_MODE = CONTENT_MODE.SEARCH;

  public isSearchMode() {
    return this.contentMode == CONTENT_MODE.SEARCH;
  }

  spinner = {
    color: 'primary' as ThemePalette,
    diameter: 150,
    loading: true
  }

  /** Table columns */
  public displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: WineExt) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: WineExt) => `${row.name}` },
    { columnDef: 'producer', header: 'Producer', cell: (row: WineExt) => `${row["producer"] ? row["producer"] : ""}` },
    { columnDef: 'country', header: 'Country', cell: (row: WineExt) => `${row["country"] ? row["country"] : ""}` },
    { columnDef: 'region', header: 'Region', cell: (row: WineExt) => `${row["region"] ? row["region"] : ""}` },
    { columnDef: 'vintage', header: 'Vintage', cell: (row: WineExt) => `${row.vintage ? row.vintage : ""}` },
    { columnDef: 'appellation', header: 'Appellation', cell: (row: WineExt) => `${row["appellation"] ? row["appellation"] : ""}` },
    { columnDef: 'colour', header: 'Colour', cell: (row: WineExt) => `${row["colour"] ? row["colour"] : ""}` },
    { columnDef: 'effervescence', header: 'Effervescence', cell: (row: WineExt) => `${row["effervescence"] ? row["effervescence"] : ""}` },
    { columnDef: 'dryness', header: 'Dryness', cell: (row: WineExt) => `${row["dryness"] ? row["dryness"] : ""}` },
    { columnDef: 'fortified', type: 'boolean', header: 'Fortified', cell: (row: WineExt) => `${row.fortified}` },
    { columnDef: 'organic', type: 'boolean', header: 'Organic', cell: (row: WineExt) => `${row.organic}` },
    { columnDef: 'degree', header: 'Degree', cell: (row: WineExt) => `${row.degree}` },
    { columnDef: 'rating', header: 'Rating', cell: (row: WineExt) => `${row.rating ? row.rating : "N/A"}` },
  ];

  //"region",
  public displayedColumns = ["id", "name", "producer", "country", "appellation", "vintage", "degree", "colour", "effervescence", "dryness", "fortified", "organic"];

  dataSource = new MatTableDataSource();
  account: Account;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public _wineService: WineService,
    public _notificationService: NotificationService,
    private _producerService: ProducerService,
    private _regionService: RegionService,
    private _countryService: CountryService,
    private _colourService: ColourService,
    private _effervescenceService: EffervescenceService,
    private _drynessService: DrynessService,
    private _appellationService: AppellationService,
    private _varietyService: VarietyService,
    private _oenologistService: OenologistService,
    private _nqService: NamedQueryService,
    public _router: Router,
    public _dialog: MatDialog) {
  }

  ngOnInit() {

    this.account = JSON.parse(localStorage.getItem(PROFILE));

    const p0 = this._producerService.getAllItems().toPromise();
    const p1 = this._regionService.getAllItems().toPromise();
    const p2 = this._countryService.getAllItems().toPromise();
    const p3 = this._colourService.getAllItems().toPromise();
    const p4 = this._effervescenceService.getAllItems().toPromise();
    const p5 = this._drynessService.getAllItems().toPromise();
    const p6 = this._appellationService.getAllItems().toPromise();
    const p7 = this._varietyService.getAllItems().toPromise();
    const p8 = this._oenologistService.getAllItems().toPromise();

    Promise.all([p0, p1, p2, p3, p4, p5, p6, p7, p8]).then((data) => {
      // console.log(data);
      data[0].forEach((item) => this.producersData.set(item["value"], item["label"]));
      data[1].forEach((item) => this.regionsData.set(item["value"], item["label"]));
      data[2].forEach((item) => this.countrysData.set(item["value"], item["label"]));
      data[3].forEach((item) => this.coloursData.set(item["value"], item["label"]));
      data[4].forEach((item) => this.effervescencesData.set(item["value"], item["label"]));
      data[5].forEach((item) => this.drynessData.set(item["value"], item["label"]));
      data[6].forEach((item) => this.appellationData.set(item["value"], item["label"]));
      this.varieties = data[7];
      data[7].forEach((item) => this.varietyData.set("" + item["value"], item["label"]));
      data[8].forEach((item) => this.oenologistData.set("" + item["value"], item["label"]));

      this.loadDefaultTable();
    });
  }

  loadDefaultTable() {
    var observable = this._nqService.getNQArray(["wine", "all"]); //this._wineService.getAll();
    if (this.contentMode == CONTENT_MODE.RECORDS) observable = this._nqService.getNQArray(["wine", "records"]);
    if (this.contentMode == CONTENT_MODE.PROPOSAL) observable = this._nqService.getNQArray(["wine", "proposals"]);
    this.tableWines(observable);
  }

  tableWines(observable) {
    observable.subscribe((data) => {
      data.forEach((item) => {
        item["producer"] = this.producersData.get(item.producerId);
        item["region"] = this.regionsData.get(item.regionId);
        item["country"] = this.countrysData.get(item.countryId);
        item["colour"] = this.coloursData.get(item.colourId);
        item["effervescence"] = this.effervescencesData.get(item.effervescenceId);
        item["dryness"] = this.drynessData.get(item.drynessId);
        item["appellation"] = this.appellationData.get(item.appellationId);
        item["oenologist"] = this.oenologistData.get(item.oenologistId);

        //es "1:2" --> traduco i codici in labels
        if (item.varietyIds) {
          let varieties = "";
          item.varietyIds.split(":").forEach(vid => {
            //console.log("vid", vid, this.varietyData.get(vid));
            varieties += this.varietyData.get(vid) + " | ";
          });
          item["varieties"] = varieties;
        }

        //es "1:2" --> traduco i codici in labels
        if (item.oenologistIds) {
          let oenologists = "";
          item.oenologistIds.split(":").forEach(vid => {
            oenologists += this.oenologistData.get(vid) + " | ";
          });
          item["oenologists"] = oenologists;
        }

      });
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.loading = false;
    },
      (error) => this._notificationService.error("SQL Search", error._body));
  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.selectedVarieties = [];
    this.loadDefaultTable();
  }

  addToList(wine) {
    var options: NQWlist[];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this._nqService.getNQArray(["wlist", "wine-lists"]).subscribe((data) => {
      dialogConfig.data = data;
      let dialog = this._dialog.open(SelectListDialogComponent, dialogConfig);
      dialog.afterClosed().subscribe(selection => {
        if (selection) {
          this._nqService.getNQSingle(["wlist", "add-wine-to-wine-list", wine.id, selection]).subscribe(() => { },
            (error) => this.manageError(error));
        }
      });
    });
  }

  viewItem(row: WineExt) {
    localStorage.setItem("WINE", JSON.stringify(row));
    console.log(row);
    this._router.navigate(["wine-view", row.id]);
  }

  rate_review(wine: WineExt) {
    this._nqService.getNQSingle(["review", "user", wine.id]).subscribe((review) => {
      console.log("review", review);
      this._router.navigate(["review-entry-form", review.id]);
    },
      (error) => {
        this._router.navigate(["review-entry-form"]);
      });
  }

  cloneWine(wine: WineExt) {
    localStorage.setItem("CLONING", "1");
    this._router.navigate(["wine-entry-form", wine.id]);
  }

  manageError(error) {
    this._notificationService.error("Deletion Error", error._body);
  }

  isBoolean(columnDef) {
    return columnDef["type"] === "boolean";
  }

  isTrue(row, column) {
    return row[column] === true;
  }

  searchByVarieties() {
    // const dialogConfig = new MatDialogConfig();
    // let dialog = this._dialog.open(SearchByVarietiesDialogComponent, dialogConfig);
    //dialog.afterClosed().subscribe(selection => {
    var observable = this._nqService.postNQSingle(this.selectedVarieties, ["wine", "search-by-varieties"]);
    this.tableWines(observable);
    //});
  }


  search() {
    var observable = this._nqService.postNQSingle({ searchLine: this.searchText, table: "Wine" }, ["search"]); //this._wineService.getAll();
    this.tableWines(observable);
  }

  sqlModeSwitch() {
    this.sqlMode = !this.sqlMode;
  }

}

