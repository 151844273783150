
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { ReviewI18n } from '../reviewi18n.model';
import { ReviewI18nService } from '../services/reviewi18n.service';



@Component({
  selector: 'app-reviewi18n-table',
  templateUrl: './reviewi18n-table.component.html',
  styleUrls: ['./reviewi18n-table.component.css']
})
export class ReviewI18nTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "ReviewI18n Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: ReviewI18n) => `${row.id ? row.id : ""}` },
    { columnDef: 'languageId', header: 'LanguageId', cell: (row: ReviewI18n) => `${row.languageId ? row.languageId : ""}` },
    { columnDef: 'reviewId', header: 'ReviewId', cell: (row: ReviewI18n) => `${row.reviewId ? row.reviewId : ""}` },
    { columnDef: 'title', header: 'Title', cell: (row: ReviewI18n) => `${row.title ? row.title : ""}` },
    { columnDef: 'content', header: 'Content', cell: (row: ReviewI18n) => `${row.content ? row.content : ""}` },
    { columnDef: 'sideNotes', header: 'SideNotes', cell: (row: ReviewI18n) => `${row.sideNotes ? row.sideNotes : ""}` },
    { columnDef: 'createdAt', header: 'CreatedAt', cell: (row: ReviewI18n) => `${row.createdAt ? row.createdAt : ""}` },
  ];

displayedColumns = [
  "id",
  "languageId",
  "reviewId",
  "title",
  "content",
  "sideNotes",
  "createdAt",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _reviewi18nService: ReviewI18nService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._reviewi18nService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["reviewi18n-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["reviewi18n-form", row.id]);
  }



}
