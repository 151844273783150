//export const API_BASE_URL = 'http://159.65.83.95:5001';
//export const API_BASE_URL = 'http://localhost:5001';
export const API_BASE_URL = 'https://oenologist.net/api';

export const ACCESS_TOKEN = 'accessToken';

export const PROFILE = 'PROFILE';

export const PROFILE_ADMIN = 'A';
export const PROFILE_USER = 'U';
export const PROFILE_PROVIDER = 'P';
export const PROFILE_VENDOR = 'V';

//export const OAUTH2_REDIRECT_URI = 'http://localhost:5000/oauth2/redirect'
//export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
//export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
//export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const LANGUAGES = [{ value: 'IT', label: 'Italian' }, { value: 'EN', label: 'English' }, { value: 'FR', label: 'French' }, { value: 'DE', label: 'Deutsche' }];

export const LANGUAGES_FLAG = new Map([
    ['IT', 'IT'],
    ['EN', 'GP'],
    ['FR', 'FR'],
    ['DE', 'DE']
]);
