
<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:23 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">

      <mat-grid-list cols="1" rowHeight="80px">


	


	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.languageId .value ">Language</mat-label>
	            <mat-error *ngIf="form.controls.languageId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="languageId" name="language" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let language of languagesData" [value]="language.value">
	              {{language.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>


	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.regionId .value ">Region</mat-label>
	            <mat-error *ngIf="form.controls.regionId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="regionId" name="region" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let region of regionsData" [value]="region.value">
	              {{region.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.name .value ">Name</mat-label>
            <input matInput type="text" required  formControlName="name">
	
	            <mat-error *ngIf="!form.controls.name.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.description .value ">Description</mat-label>
            <input matInput type="text"    formControlName="description">
	            <mat-icon matSuffix (click)="initEditor('description', 2147483647)">edit</mat-icon>
	
	          </mat-form-field>
        </mat-grid-tile>	
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!regioni18n?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>