// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { CountryWithI18nService } from './country-with-i18n.service';
import { CountryI18nService } from 'src/app/entities/countryi18n/services/countryi18n.service';
import { CountryI18n } from 'src/app/entities/countryi18n/countryi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface CountryTableRow extends TableRow<CountryI18n> {
}

export interface CountryWithI18n {
	id: number; 
	name: string;
	description: string; 
	i18n: CountryI18n[];
}


@Component({
  selector: 'app-country-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class CountryWithI18nComponent extends CommonFormComponent<CountryWithI18n, CountryI18n> implements AfterViewInit {

  foreignKeyFieldName = "countryId";
  listRoute= "country-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: CountryTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: CountryTableRow) => `${row.name}` },
  ];

  public title = 'Country List';
  public entity = 'Country';



  constructor(
    _languageService: LanguageService,
    _countryWithI18nService: CountryWithI18nService,
    _countryI18nService: CountryI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _countryWithI18nService, _countryI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();


      this.fetchDataAndLanguages();

  }

  getTableRow(data: CountryWithI18n): CountryTableRow {
    let row = {} as CountryTableRow;
    row = Object.assign(data, row);
    return row;
  }


}
