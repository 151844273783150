
<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2022-01-10 ( Time 14:20:21 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">

      <mat-grid-list cols="2" rowHeight="80px">


	


	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.wineId .value ">Wine</mat-label>
	            <mat-error *ngIf="form.controls.wineId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="wineId" name="wine" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let wine of winesData" [value]="wine.value">
	              {{wine.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>


	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.languageId .value ">Language</mat-label>
	            <mat-error *ngIf="form.controls.languageId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="languageId" name="language" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let language of languagesData" [value]="language.value">
	              {{language.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>


	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.authorId .value ">Account</mat-label>
	            <mat-error *ngIf="form.controls.authorId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="authorId" name="account" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let account of accountsData" [value]="account.value">
	              {{account.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.rating .value ">Rating</mat-label>
            <input matInput type="number" required  formControlName="rating">
	
	            <mat-error *ngIf="!form.controls.rating.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.title .value ">Title</mat-label>
            <input matInput type="text" required  formControlName="title">
	
	            <mat-error *ngIf="!form.controls.title.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.content .value ">Content</mat-label>
            <input matInput type="text" required  formControlName="content">
	
	            <mat-error *ngIf="!form.controls.content.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.sideNotes .value ">SideNotes</mat-label>
            <input matInput type="text"    formControlName="sideNotes">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.createdAt .value ">CreatedAt</mat-label>
            <input matInput type="date"    formControlName="createdAt">
	
	          </mat-form-field>
        </mat-grid-tile>	
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!review?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>