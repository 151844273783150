// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-17 ( Time 09:49:48 )

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VarietyService } from '../entities/variety/services/variety.service';
import { ListItem } from '../services/generic.service';

@Component({
  selector: 'app-search-by-varieties-dialog',
  templateUrl: './search-by-varieties-dialog.component.html',
  styleUrls: ['./search-by-varieties-dialog.component.css']
})
export class SearchByVarietiesDialogComponent implements OnInit {

  varietiesData: ListItem[];
  selectedChoices: string;

  constructor(
    public dialogRef: MatDialogRef<SearchByVarietiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _varietyService: VarietyService) {
  }


  ngOnInit() {
    //carico le varieties
    const p0 = this._varietyService.getAllItems().toPromise();
    Promise.all([p0]).then((data) => {
      this.varietiesData = data[0];
    });
  };


  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmSelection(): void {
    this.dialogRef.close(this.selectedChoices);
  }


}

