// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2022-01-10 ( Time 14:20:21 )

import { GenericService } from './../../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Review } from '../review.model';
import { Configuration } from '../../../app.configuration';

@Injectable()
export class ReviewService extends GenericService<Review>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'review/');
    }

}
