// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-31 ( Time 13:51:02 )

import { Router, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';

//custom imports
import { customAppRoutings } from './custom_app.routing';

import { 
    AuthGuardService as AuthGuard 
} from './services/auth-guard.service';
  

// Account
import { AccountTableComponent } from './entities/account/account-table/account-table.component';
import { AccountFormComponent } from './entities/account/account-form/account-form.component';

// Appellation
import { AppellationTableComponent } from './entities/appellation/appellation-table/appellation-table.component';
import { AppellationFormComponent } from './entities/appellation/appellation-form/appellation-form.component';

// AppellationI18n
import { AppellationI18nTableComponent } from './entities/appellationi18n/appellationi18n-table/appellationi18n-table.component';
import { AppellationI18nFormComponent } from './entities/appellationi18n/appellationi18n-form/appellationi18n-form.component';

// AppellationOenologistRel
import { AppellationOenologistRelTableComponent } from './entities/appellationoenologistrel/appellationoenologistrel-table/appellationoenologistrel-table.component';
import { AppellationOenologistRelFormComponent } from './entities/appellationoenologistrel/appellationoenologistrel-form/appellationoenologistrel-form.component';

// AppellationVarietyRel
import { AppellationVarietyRelTableComponent } from './entities/appellationvarietyrel/appellationvarietyrel-table/appellationvarietyrel-table.component';
import { AppellationVarietyRelFormComponent } from './entities/appellationvarietyrel/appellationvarietyrel-form/appellationvarietyrel-form.component';

// Award
import { AwardTableComponent } from './entities/award/award-table/award-table.component';
import { AwardFormComponent } from './entities/award/award-form/award-form.component';

// AwardI18n
import { AwardI18nTableComponent } from './entities/awardi18n/awardi18n-table/awardi18n-table.component';
import { AwardI18nFormComponent } from './entities/awardi18n/awardi18n-form/awardi18n-form.component';

// Certification
import { CertificationTableComponent } from './entities/certification/certification-table/certification-table.component';
import { CertificationFormComponent } from './entities/certification/certification-form/certification-form.component';

// CertificationI18n
import { CertificationI18nTableComponent } from './entities/certificationi18n/certificationi18n-table/certificationi18n-table.component';
import { CertificationI18nFormComponent } from './entities/certificationi18n/certificationi18n-form/certificationi18n-form.component';

// Colour
import { ColourTableComponent } from './entities/colour/colour-table/colour-table.component';
import { ColourFormComponent } from './entities/colour/colour-form/colour-form.component';

// ColourI18n
import { ColourI18nTableComponent } from './entities/colouri18n/colouri18n-table/colouri18n-table.component';
import { ColourI18nFormComponent } from './entities/colouri18n/colouri18n-form/colouri18n-form.component';

// Country
import { CountryTableComponent } from './entities/country/country-table/country-table.component';
import { CountryFormComponent } from './entities/country/country-form/country-form.component';

// CountryI18n
import { CountryI18nTableComponent } from './entities/countryi18n/countryi18n-table/countryi18n-table.component';
import { CountryI18nFormComponent } from './entities/countryi18n/countryi18n-form/countryi18n-form.component';

// Dryness
import { DrynessTableComponent } from './entities/dryness/dryness-table/dryness-table.component';
import { DrynessFormComponent } from './entities/dryness/dryness-form/dryness-form.component';

// DrynessI18n
import { DrynessI18nTableComponent } from './entities/drynessi18n/drynessi18n-table/drynessi18n-table.component';
import { DrynessI18nFormComponent } from './entities/drynessi18n/drynessi18n-form/drynessi18n-form.component';

// Effervescence
import { EffervescenceTableComponent } from './entities/effervescence/effervescence-table/effervescence-table.component';
import { EffervescenceFormComponent } from './entities/effervescence/effervescence-form/effervescence-form.component';

// EffervescenceI18n
import { EffervescenceI18nTableComponent } from './entities/effervescencei18n/effervescencei18n-table/effervescencei18n-table.component';
import { EffervescenceI18nFormComponent } from './entities/effervescencei18n/effervescencei18n-form/effervescencei18n-form.component';

// Language
import { LanguageTableComponent } from './entities/language/language-table/language-table.component';
import { LanguageFormComponent } from './entities/language/language-form/language-form.component';

// Oenologist
import { OenologistTableComponent } from './entities/oenologist/oenologist-table/oenologist-table.component';
import { OenologistFormComponent } from './entities/oenologist/oenologist-form/oenologist-form.component';

// OenologistI18n
import { OenologistI18nTableComponent } from './entities/oenologisti18n/oenologisti18n-table/oenologisti18n-table.component';
import { OenologistI18nFormComponent } from './entities/oenologisti18n/oenologisti18n-form/oenologisti18n-form.component';

// Producer
import { ProducerTableComponent } from './entities/producer/producer-table/producer-table.component';
import { ProducerFormComponent } from './entities/producer/producer-form/producer-form.component';

// ProducerI18n
import { ProducerI18nTableComponent } from './entities/produceri18n/produceri18n-table/produceri18n-table.component';
import { ProducerI18nFormComponent } from './entities/produceri18n/produceri18n-form/produceri18n-form.component';

// Region
import { RegionTableComponent } from './entities/region/region-table/region-table.component';
import { RegionFormComponent } from './entities/region/region-form/region-form.component';

// RegionI18n
import { RegionI18nTableComponent } from './entities/regioni18n/regioni18n-table/regioni18n-table.component';
import { RegionI18nFormComponent } from './entities/regioni18n/regioni18n-form/regioni18n-form.component';

// Review
import { ReviewTableComponent } from './entities/review/review-table/review-table.component';
import { ReviewFormComponent } from './entities/review/review-form/review-form.component';

// ReviewI18n
import { ReviewI18nTableComponent } from './entities/reviewi18n/reviewi18n-table/reviewi18n-table.component';
import { ReviewI18nFormComponent } from './entities/reviewi18n/reviewi18n-form/reviewi18n-form.component';

// Variety
import { VarietyTableComponent } from './entities/variety/variety-table/variety-table.component';
import { VarietyFormComponent } from './entities/variety/variety-form/variety-form.component';

// VarietyI18n
import { VarietyI18nTableComponent } from './entities/varietyi18n/varietyi18n-table/varietyi18n-table.component';
import { VarietyI18nFormComponent } from './entities/varietyi18n/varietyi18n-form/varietyi18n-form.component';

// VarietySynonym
import { VarietySynonymTableComponent } from './entities/varietysynonym/varietysynonym-table/varietysynonym-table.component';
import { VarietySynonymFormComponent } from './entities/varietysynonym/varietysynonym-form/varietysynonym-form.component';

// Vendor
import { VendorTableComponent } from './entities/vendor/vendor-table/vendor-table.component';
import { VendorFormComponent } from './entities/vendor/vendor-form/vendor-form.component';

// VendorI18n
import { VendorI18nTableComponent } from './entities/vendori18n/vendori18n-table/vendori18n-table.component';
import { VendorI18nFormComponent } from './entities/vendori18n/vendori18n-form/vendori18n-form.component';

// Wine
import { WineTableComponent } from './entities/wine/wine-table/wine-table.component';
import { WineFormComponent } from './entities/wine/wine-form/wine-form.component';

// WineI18n
import { WineI18nTableComponent } from './entities/winei18n/winei18n-table/winei18n-table.component';
import { WineI18nFormComponent } from './entities/winei18n/winei18n-form/winei18n-form.component';

// WineOenologistRel
import { WineOenologistRelTableComponent } from './entities/wineoenologistrel/wineoenologistrel-table/wineoenologistrel-table.component';
import { WineOenologistRelFormComponent } from './entities/wineoenologistrel/wineoenologistrel-form/wineoenologistrel-form.component';

// WineVarietyRel
import { WineVarietyRelTableComponent } from './entities/winevarietyrel/winevarietyrel-table/winevarietyrel-table.component';
import { WineVarietyRelFormComponent } from './entities/winevarietyrel/winevarietyrel-form/winevarietyrel-form.component';

// WineWlistRel
import { WineWlistRelTableComponent } from './entities/winewlistrel/winewlistrel-table/winewlistrel-table.component';
import { WineWlistRelFormComponent } from './entities/winewlistrel/winewlistrel-form/winewlistrel-form.component';

// Wlist
import { WlistTableComponent } from './entities/wlist/wlist-table/wlist-table.component';
import { WlistFormComponent } from './entities/wlist/wlist-form/wlist-form.component';

export const routing = RouterModule.forRoot([
    { path: '', component: HomeComponent, canActivate: [AuthGuard]  },
    ...customAppRoutings,
    { path: 'account-table', component: AccountTableComponent, canActivate: [AuthGuard]  },
    { path: 'account-form/:id', component: AccountFormComponent, canActivate: [AuthGuard]  },
    { path: 'account-form', component: AccountFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellation-table', component: AppellationTableComponent, canActivate: [AuthGuard]  },
    { path: 'appellation-form/:id', component: AppellationFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellation-form', component: AppellationFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationi18n-table', component: AppellationI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'appellationi18n-form/:id', component: AppellationI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationi18n-form', component: AppellationI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationoenologistrel-table', component: AppellationOenologistRelTableComponent, canActivate: [AuthGuard]  },
    { path: 'appellationoenologistrel-form/:id', component: AppellationOenologistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationoenologistrel-form', component: AppellationOenologistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationvarietyrel-table', component: AppellationVarietyRelTableComponent, canActivate: [AuthGuard]  },
    { path: 'appellationvarietyrel-form/:id', component: AppellationVarietyRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'appellationvarietyrel-form', component: AppellationVarietyRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'award-table', component: AwardTableComponent, canActivate: [AuthGuard]  },
    { path: 'award-form/:id', component: AwardFormComponent, canActivate: [AuthGuard]  },
    { path: 'award-form', component: AwardFormComponent, canActivate: [AuthGuard]  },
    { path: 'awardi18n-table', component: AwardI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'awardi18n-form/:id', component: AwardI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'awardi18n-form', component: AwardI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'certification-table', component: CertificationTableComponent, canActivate: [AuthGuard]  },
    { path: 'certification-form/:id', component: CertificationFormComponent, canActivate: [AuthGuard]  },
    { path: 'certification-form', component: CertificationFormComponent, canActivate: [AuthGuard]  },
    { path: 'certificationi18n-table', component: CertificationI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'certificationi18n-form/:id', component: CertificationI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'certificationi18n-form', component: CertificationI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'colour-table', component: ColourTableComponent, canActivate: [AuthGuard]  },
    { path: 'colour-form/:id', component: ColourFormComponent, canActivate: [AuthGuard]  },
    { path: 'colour-form', component: ColourFormComponent, canActivate: [AuthGuard]  },
    { path: 'colouri18n-table', component: ColourI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'colouri18n-form/:id', component: ColourI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'colouri18n-form', component: ColourI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'country-table', component: CountryTableComponent, canActivate: [AuthGuard]  },
    { path: 'country-form/:id', component: CountryFormComponent, canActivate: [AuthGuard]  },
    { path: 'country-form', component: CountryFormComponent, canActivate: [AuthGuard]  },
    { path: 'countryi18n-table', component: CountryI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'countryi18n-form/:id', component: CountryI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'countryi18n-form', component: CountryI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'dryness-table', component: DrynessTableComponent, canActivate: [AuthGuard]  },
    { path: 'dryness-form/:id', component: DrynessFormComponent, canActivate: [AuthGuard]  },
    { path: 'dryness-form', component: DrynessFormComponent, canActivate: [AuthGuard]  },
    { path: 'drynessi18n-table', component: DrynessI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'drynessi18n-form/:id', component: DrynessI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'drynessi18n-form', component: DrynessI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'effervescence-table', component: EffervescenceTableComponent, canActivate: [AuthGuard]  },
    { path: 'effervescence-form/:id', component: EffervescenceFormComponent, canActivate: [AuthGuard]  },
    { path: 'effervescence-form', component: EffervescenceFormComponent, canActivate: [AuthGuard]  },
    { path: 'effervescencei18n-table', component: EffervescenceI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'effervescencei18n-form/:id', component: EffervescenceI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'effervescencei18n-form', component: EffervescenceI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'language-table', component: LanguageTableComponent, canActivate: [AuthGuard]  },
    { path: 'language-form/:id', component: LanguageFormComponent, canActivate: [AuthGuard]  },
    { path: 'language-form', component: LanguageFormComponent, canActivate: [AuthGuard]  },
    { path: 'oenologist-table', component: OenologistTableComponent, canActivate: [AuthGuard]  },
    { path: 'oenologist-form/:id', component: OenologistFormComponent, canActivate: [AuthGuard]  },
    { path: 'oenologist-form', component: OenologistFormComponent, canActivate: [AuthGuard]  },
    { path: 'oenologisti18n-table', component: OenologistI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'oenologisti18n-form/:id', component: OenologistI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'oenologisti18n-form', component: OenologistI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'producer-table', component: ProducerTableComponent, canActivate: [AuthGuard]  },
    { path: 'producer-form/:id', component: ProducerFormComponent, canActivate: [AuthGuard]  },
    { path: 'producer-form', component: ProducerFormComponent, canActivate: [AuthGuard]  },
    { path: 'produceri18n-table', component: ProducerI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'produceri18n-form/:id', component: ProducerI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'produceri18n-form', component: ProducerI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'region-table', component: RegionTableComponent, canActivate: [AuthGuard]  },
    { path: 'region-form/:id', component: RegionFormComponent, canActivate: [AuthGuard]  },
    { path: 'region-form', component: RegionFormComponent, canActivate: [AuthGuard]  },
    { path: 'regioni18n-table', component: RegionI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'regioni18n-form/:id', component: RegionI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'regioni18n-form', component: RegionI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'review-table', component: ReviewTableComponent, canActivate: [AuthGuard]  },
    { path: 'review-form/:id', component: ReviewFormComponent, canActivate: [AuthGuard]  },
    { path: 'review-form', component: ReviewFormComponent, canActivate: [AuthGuard]  },
    { path: 'reviewi18n-table', component: ReviewI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'reviewi18n-form/:id', component: ReviewI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'reviewi18n-form', component: ReviewI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'variety-table', component: VarietyTableComponent, canActivate: [AuthGuard]  },
    { path: 'variety-form/:id', component: VarietyFormComponent, canActivate: [AuthGuard]  },
    { path: 'variety-form', component: VarietyFormComponent, canActivate: [AuthGuard]  },
    { path: 'varietyi18n-table', component: VarietyI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'varietyi18n-form/:id', component: VarietyI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'varietyi18n-form', component: VarietyI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'varietysynonym-table', component: VarietySynonymTableComponent, canActivate: [AuthGuard]  },
    { path: 'varietysynonym-form/:id', component: VarietySynonymFormComponent, canActivate: [AuthGuard]  },
    { path: 'varietysynonym-form', component: VarietySynonymFormComponent, canActivate: [AuthGuard]  },
    { path: 'vendor-table', component: VendorTableComponent, canActivate: [AuthGuard]  },
    { path: 'vendor-form/:id', component: VendorFormComponent, canActivate: [AuthGuard]  },
    { path: 'vendor-form', component: VendorFormComponent, canActivate: [AuthGuard]  },
    { path: 'vendori18n-table', component: VendorI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'vendori18n-form/:id', component: VendorI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'vendori18n-form', component: VendorI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'wine-table', component: WineTableComponent, canActivate: [AuthGuard]  },
    { path: 'wine-form/:id', component: WineFormComponent, canActivate: [AuthGuard]  },
    { path: 'wine-form', component: WineFormComponent, canActivate: [AuthGuard]  },
    { path: 'winei18n-table', component: WineI18nTableComponent, canActivate: [AuthGuard]  },
    { path: 'winei18n-form/:id', component: WineI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'winei18n-form', component: WineI18nFormComponent, canActivate: [AuthGuard]  },
    { path: 'wineoenologistrel-table', component: WineOenologistRelTableComponent, canActivate: [AuthGuard]  },
    { path: 'wineoenologistrel-form/:id', component: WineOenologistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'wineoenologistrel-form', component: WineOenologistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'winevarietyrel-table', component: WineVarietyRelTableComponent, canActivate: [AuthGuard]  },
    { path: 'winevarietyrel-form/:id', component: WineVarietyRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'winevarietyrel-form', component: WineVarietyRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'winewlistrel-table', component: WineWlistRelTableComponent, canActivate: [AuthGuard]  },
    { path: 'winewlistrel-form/:id', component: WineWlistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'winewlistrel-form', component: WineWlistRelFormComponent, canActivate: [AuthGuard]  },
    { path: 'wlist-table', component: WlistTableComponent, canActivate: [AuthGuard]  },
    { path: 'wlist-form/:id', component: WlistFormComponent, canActivate: [AuthGuard]  },
    { path: 'wlist-form', component: WlistFormComponent, canActivate: [AuthGuard]  },
    { path: 'about', component: AboutComponent },
    { path: '**', component: NotFoundComponent  }
]);