
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { AppellationService } from '../entities/appellation/services/appellation.service';
import { AppellationVarietyRel } from '../entities/appellationvarietyrel/appellationvarietyrel.model';
import { AppellationVarietyRelService } from '../entities/appellationvarietyrel/services/appellationvarietyrel.service';
import { VarietyService } from '../entities/variety/services/variety.service';
import { VarietySynonymService } from '../entities/varietysynonym/services/varietysynonym.service';

export interface AppellationVarietyRelExt extends AppellationVarietyRel {
  variety?: string;
  appellation?: string;
}

@Component({
  selector: 'app-appellation-variety-table',
  templateUrl: './appellation-variety-table.component.html',
  styleUrls: ['./appellation-variety-table.component.css']
})
export class AppellationVarietyTableComponent implements AfterViewInit {

  public filterText: string = null;
  public varietiesData = new Map<number, string>();
  public appellationsData = new Map<number, string>();

  title = "Appellation -> Variety";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: AppellationVarietyRel) => `${row.id ? row.id : ""}` },
    { columnDef: 'appellation', header: 'Appellation', cell: (row: AppellationVarietyRelExt) => `${row.appellation ? row.appellation : ""}` },
    { columnDef: 'variety', header: 'Variety', cell: (row: AppellationVarietyRelExt) => `${row.variety ? row.variety : ""}` },
    { columnDef: 'percentage', header: 'Percentage', cell: (row: AppellationVarietyRelExt) => `${row.percentage ? row.percentage : ""}` }
  ];

  displayedColumns = [
    "id",
    "appellation",
    "variety",
    "percentage"
  ];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    public _notificationService: NotificationService,
    private _varietyService: VarietyService,
    private _appelationService: AppellationService,
    private _appellationvarietyrelService: AppellationVarietyRelService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngOnInit() {
    const p0 = this._varietyService.getAllItems().toPromise();
    const p1 = this._appelationService.getAllItems().toPromise();

    Promise.all([p0, p1]).then((data) => {
      data[0].forEach((item) => this.varietiesData.set(item["value"], item["label"]));
      data[1].forEach((item) => this.appellationsData.set(item["value"], item["label"]));
      this.loadDefaultTable();
    });
  }

  ngAfterViewInit() {}
  
  loadDefaultTable() {

    this._appellationvarietyrelService.getAll().subscribe((data) => {

      data.forEach((item) => {
        item["variety"] = this.varietiesData.get(item.varietyId);
        item["appellation"] = this.appellationsData.get(item.appellationId);
      });

      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["appellationvarietyrel-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["appellationvarietyrel-form", row.id]);
  }



}
