// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { ColourWithI18nService } from './colour-with-i18n.service';
import { ColourI18nService } from 'src/app/entities/colouri18n/services/colouri18n.service';
import { ColourI18n } from 'src/app/entities/colouri18n/colouri18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface ColourTableRow extends TableRow<ColourI18n> {
}

export interface ColourWithI18n {
	id: number; 
	name: string;
	description: string; 
	i18n: ColourI18n[];
}


@Component({
  selector: 'app-colour-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class ColourWithI18nComponent extends CommonFormComponent<ColourWithI18n, ColourI18n> implements AfterViewInit {

  foreignKeyFieldName = "colourId";
  listRoute= "colour-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: ColourTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: ColourTableRow) => `${row.name}` },
  ];

  public title = 'Colour List';
  public entity = 'Colour';



  constructor(
    _languageService: LanguageService,
    _colourWithI18nService: ColourWithI18nService,
    _colourI18nService: ColourI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _colourWithI18nService, _colourI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();


      this.fetchDataAndLanguages();

  }

  getTableRow(data: ColourWithI18n): ColourTableRow {
    let row = {} as ColourTableRow;
    row = Object.assign(data, row);
    return row;
  }


}
