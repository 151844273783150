// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-06-06 ( Time 10:06:26 )

// Modules imports
import { NotificationService } from './../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../delete-dialog/delete-dialog.component';

// Models and services imports
import * as _ from 'underscore';
import { Review } from 'src/app/entities/review/review.model';
import { ReviewService } from 'src/app/entities/review/services/review.service';
import { WineExt } from 'src/app/wine-view/wine-view.component';
import { NamedQueryService } from 'src/app/services/named-query.service';

@Component({
  selector: 'app-review-entry-form',
  templateUrl: './review-entry-form.component.html',
  styleUrls: ['./review-entry-form.component.css']
})
export class ReviewEntryFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'Review Form';
  public review: Review;
  public form: FormGroup;
  public ids;
  public wine: WineExt;

  rating: number = 3;
  starColor: "primary";

  constructor(
    public _nqService: NamedQueryService,
    public _reviewService: ReviewService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.wine = JSON.parse(localStorage.getItem("WINE"));
    this.getIdFromRouteParams();
    this.initForm();
  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
      this.ids = _.values(p);
    });
  }

  onRatingChanged(rating) {
    this.rating = rating;
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (review?: Review) => {
    return {
      title: [
        (review ? review.title : ''),
        Validators.required
      ],
      content: [
        (review ? review.content : ''),
        Validators.required
      ],
      sideNotes: [
        (review ? review.sideNotes : '')
      ],
    };
  }


  load = () => {
    this._reviewService.getSingle(this.ids).subscribe(
      (review: Review) => {
        this.review = review;
        this.rating = review.rating;
        this.form = this._formBuilder.group(this.getNewForm(this.review));
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
    // If we didn't get a review, we are adding a review
    if (!this.review) {
      this.add();
    } else { // If we didn't get a review, we are adding a review
      this.update();
    }
  }

  add = () => {

    this.review = this.form.value;
    this.review.wineId = this.wine.id;
    this.review.rating = this.rating;

    this._nqService.postNQSingle(this.form.value, ["review", "add"]).subscribe(
      result => {
        this._notificationService.success('Success', 'Review added successfuly');
        console.log("result", result);
        this._router.navigate(['./review-entry-form/' + result.id]);
      },
      error => {
        1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {

    this.review = { ...this.review, ... this.form.value };
    this.review.rating = this.rating;

    this._reviewService.update(this.review, this.ids).subscribe(
      result => this._notificationService.success('Success', 'Review edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.review.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `review: ${this.review.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.review.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._reviewService.delete(id).subscribe(
      result => {
        this._router.navigate(['./review-table']);
        this._notificationService.success(
          'Deleted',
          `The review entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
