<div class="material-card-narrow mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">Register</h2>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="submit()">

    <div class="add-edit-form">

      <mat-grid-list cols="1" rowHeight="80px">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.name.value">Name</mat-label>
            <input type="text" matInput required formControlName="name">
            <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.name.errors.required">Name is required.</mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.email.value">Email</mat-label>
            <input type="email" matInput required formControlName="email">
            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.email.errors.required">Email is required.</mat-error>
              <mat-error *ngIf="f.email.errors.email">
                Please, enter valid email address.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.password.value">Password</mat-label>
            <input type="password" matInput required formControlName="password">
            <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.password.errors.required">
                Password is required.
              </mat-error>
              <mat-error *ngIf="f.password.errors.minlength">
                Password should be at least 8 character.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.matchingPassword.value">Password</mat-label>
            <input type="password" matInput required formControlName="matchingPassword">
            <div *ngIf="f.matchingPassword.touched && f.matchingPassword.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.matchingPassword.errors.required">
                Password is required.
              </mat-error>
              <mat-error *ngIf="f.matchingPassword.errors.minlength">
                Password should be at least 8 character.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.language.value">Language</mat-label>
            <mat-select required formControlName="language">
              <mat-option *ngFor="let language of languages" [value]="language.isoCode">
                {{language.englishName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.type.value">Account Type</mat-label>
            <mat-select required formControlName="type"
              (selectionChange)="loadAccounts($event.value)">
              <mat-option *ngFor="let type of accountTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="f.type.value == 'Producer'">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.producer.value">Producer</mat-label>
            <mat-select required formControlName="producer">
              <mat-option *ngFor="let producer of producers" [value]="producer.value">
                {{producer.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="f.type.value == 'Vendor'">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.vendor.value">Vendor</mat-label>
            <mat-select required formControlName="vendor">
              <mat-option *ngFor="let vendor of vendors" [value]="vendor.value">
                {{vendor.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

      </mat-grid-list>

    </div>

    <div class="mdl-card__actions mdl-card--border">
      <div class="card-footer-button form-button">
        <button class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button" [disabled]="!form.valid"
          type="submit">Submit</button>
      </div>
    </div>

  </form>
</div>