// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { Country } from '../../entities/country/country.model';
import { CountryService } from '../../entities/country/services/country.service';
import { Region } from '../../entities/region/region.model';
import { RegionService } from '../../entities/region/services/region.service';
import { Certification } from '../../entities/certification/certification.model';
import { CertificationService } from '../../entities/certification/services/certification.service';

import { AppellationWithI18nService } from './appellation-with-i18n.service';
import { AppellationI18nService } from 'src/app/entities/appellationi18n/services/appellationi18n.service';
import { AppellationI18n } from 'src/app/entities/appellationi18n/appellationi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface AppellationTableRow extends TableRow<AppellationI18n> {
  country: string;
  region: string;
  certification: string;
}

export interface AppellationWithI18n {
	id: number; 
    countryId: number;
    regionId: number;
    certificationId: number;
	name: string;
	description: string; 
	i18n: AppellationI18n[];
}

@Component({
  selector: 'app-appellation-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class AppellationWithI18nComponent extends CommonFormComponent<AppellationWithI18n, AppellationI18n> implements AfterViewInit {

  foreignKeyFieldName = "appellationId";
  listRoute= "appellation-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: AppellationTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: AppellationTableRow) => `${row.name}` },
    { columnDef: 'country', header: 'Country', cell: (row: AppellationTableRow) =>  `${row.country }` },
    { columnDef: 'region', header: 'Region of origin', cell: (row: AppellationTableRow) =>  `${row.region }` },
    { columnDef: 'certification', header: 'Certification', cell: (row: AppellationTableRow) =>  `${row.certification }` },
  ];

  public title = 'Appellation List';
  public entity = 'Appellation';


  public countryList: Country[];
  public countryMap = new Map<number, Country>();

  public regionList: Region[];
  public regionMap = new Map<number, Region>();

  public certificationList: Certification[];
  public certificationMap = new Map<number, Certification>();


  constructor(
   	private _countryService: CountryService,
   	private _regionService: RegionService,
   	private _certificationService: CertificationService,
    _languageService: LanguageService,
    _appellationWithI18nService: AppellationWithI18nService,
    _appellationI18nService: AppellationI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _appellationWithI18nService, _appellationI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();

    forkJoin([
this._countryService.getAll(),
this._regionService.getAll(),
this._certificationService.getAll(),
]).subscribe(([
countryList,
regionList,
certificationList,
]) => {
      this.setCountryList(countryList);
      this.setRegionList(regionList);
      this.setCertificationList(certificationList);

      this.fetchDataAndLanguages();
    });

  }

  getTableRow(data: AppellationWithI18n): AppellationTableRow {
    let row = {} as AppellationTableRow;
    row = Object.assign(data, row);
    row.country = this.countryMap.get(data.countryId)?.name;
    row.region = this.regionMap.get(data.regionId)?.name;
    row.certification = this.certificationMap.get(data.certificationId)?.name;
    return row;
  }

  setCountryList = (data: Country[]) => {
    this.countryList = data;
    data.forEach((item) => this.countryMap.set(item.id, item));
  }
  setRegionList = (data: Region[]) => {
    this.regionList = data;
    data.forEach((item) => this.regionMap.set(item.id, item));
  }
  setCertificationList = (data: Certification[]) => {
    this.certificationList = data;
    data.forEach((item) => this.certificationMap.set(item.id, item));
  }

}
