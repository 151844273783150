<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-06-06 ( Time 10:06:26 ) -->

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>

  <mat-card>
    <mat-card-header>
      <div mat-card-avatar></div>
      <mat-card-title>{{wine.name}}</mat-card-title>
      <mat-card-subtitle>{{wine.producer}}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">

      <mat-grid-list cols="1" rowHeight="80px">

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-star-rating [rating]="rating" [starCount]="5" [color]="starColor"
            (ratingUpdated)="onRatingChanged($event)"></mat-star-rating>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.title .value ">Title</mat-label>
            <input matInput type="text" required formControlName="title">
            <mat-error *ngIf="!form.controls.title.valid">
              field is required
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.content .value ">Content</mat-label>
            <input matInput type="text" required formControlName="content">
            <mat-icon matSuffix (click)="initEditor('content', 2147483647)">edit</mat-icon>
            <mat-error *ngIf="!form.controls.content.valid">
              field is required
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.sideNotes .value ">SideNotes</mat-label>
            <input matInput type="text" formControlName="sideNotes">
            <mat-icon matSuffix (click)="initEditor('sideNotes', 2147483647)">edit</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

      </mat-grid-list>
    </div>


    <div id="buttonDiv">
      <button mat-raised-button (click)="initForm()">
        Reset
      </button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
        Save
      </button>
      <button mat-raised-button color="warn" [disabled]="!review?.id" (click)="deleteConfirmation()">
        Delete
      </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>