import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PROFILE } from "../constants";
import { Account } from '../entities/account/account.model';
import { NotificationService } from '../services/notification.service';
import { Wine } from '../entities/wine/wine.model';
import { WineService } from '../entities/wine/services/wine.service';
import { NamedQueryService } from '../services/named-query.service';

export interface WineExt extends Wine {
  producer?: string;
  colour?: string;
  effervescence?: string;
  dryness?: string;
  region?: string;
  country?: string;
  appellation?: string;
  rating?: number;
  varieties?: string;
  oenologists?: string;
}

@Component({
  selector: 'app-wine-view',
  templateUrl: './wine-view.component.html',
  styleUrls: ['./wine-view.component.css']
})
export class WineViewComponent implements OnInit {

  account: Account;
  editable: boolean;
  approvable: boolean;
  wine: WineExt;
  reviewCount: number = 0;
  avgRating: number = 0;

  constructor(
    public _nqService: NamedQueryService,
    public _wineService: WineService,
    public _notificationService: NotificationService,
    private _router: Router) { }

  ngOnInit(): void {

    this.account = JSON.parse(localStorage.getItem(PROFILE));
    this.wine = JSON.parse(localStorage.getItem("WINE"));
    this.editable = this.account.type == 'A' || (this.account.id == this.wine.authorId && !this.wine.approved);
    this.approvable = this.account.type == 'A' && !this.wine.approved;

    //il rating ci sarebbe nel wine ma ci manca il numero delle reviews
    this._nqService.getNQArray(["review", "rating", this.wine.id]).subscribe((reviews) => {
      this.reviewCount = reviews.length;
      var total = 0;
      for (var i = 0; i < reviews.length; i++) {
        total += reviews[i].rating;
      }
      this.avgRating = reviews.length > 0 ? total / reviews.length : 0;
    });
  }

  reviewsList() {
    this._router.navigate(["review-list", this.wine.id]);
  }

  editItem() {
    this._router.navigate(["wine-entry-form", this.wine.id]);
  }

  approveItem() {
    this.wine.approved = true;
    this._wineService.update(this.wine, [this.wine.id]).subscribe((wine) => {
      this._notificationService.success('Approved', 'Wine Record has been approved');
      this._router.navigate(["wines-proposals-table"]);
    }, (error) => {
      this._notificationService.error('Error', 'An error occured when trying to approve the wine record');
    });
  }
}
