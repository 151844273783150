<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-02-23 ( Time 17:47:02 ) -->

<div class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
  <header class="mdl-layout__header" [ngClass]="profileType">
    <div class="mdl-layout__header-row">

      <!-- Title -->
      <!-- <mat-icon>home</mat-icon> -->
      <img style="max-height:100%; max-width:100%;" src="../../assets/images/logo.jpg" alt="">
            

      <span class="mdl-layout-title nav-home-title" routerLink="/wines-search-table" style="cursor: pointer">
        <!-- {{ title }} -->
      </span>
      <div class="mdl-layout-spacer"></div>


      <a *ngFor="let item of menuListHeader" routerLink="{{item.routerLink}}" class="mdl-navigation__link">
        <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
      </a>

      <button mat-icon-button [matMenuTriggerFor]="menuWines">
        <mat-icon>wine_bar</mat-icon>
        <mat-menu #menuWines="matMenu">
          <button *ngFor="let item of menuListWines" mat-menu-item>
            <a routerLink="{{item.routerLink}}" class="mdl-navigation__link" href="">
              <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
            </a>
          </button>
        </mat-menu>
        <span>
          Wines
        </span>
      </button>

      <button *ngIf="menuListActors.length > 0" mat-icon-button [matMenuTriggerFor]="menuActors">
        <mat-icon>people_alt</mat-icon>
        <mat-menu #menuActors="matMenu">
          <button *ngFor="let item of menuListActors" mat-menu-item>
            <a routerLink="{{item.routerLink}}" class="mdl-navigation__link" href="">
              <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
            </a>
          </button>
        </mat-menu>
        <span>
          Actors
        </span>
      </button>

      <button *ngIf="menuListUtils.length > 0" mat-icon-button [matMenuTriggerFor]="menuUtils">
        <mat-icon>construction</mat-icon>
        <mat-menu #menuUtils="matMenu">
          <button *ngFor="let item of menuListUtils" mat-menu-item>
            <a routerLink="{{item.routerLink}}" class="mdl-navigation__link" href="">
              <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
            </a>
          </button>
          <button *ngFor="let item of hrefMenuList" mat-menu-item>
            <a class="mdl-navigation__link" href="{{item.routerLink}}">
              <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
            </a>
          </button>
        </mat-menu>
        <span>
          Tools
        </span>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menuAccount">
        <mat-icon *ngIf="logged">face</mat-icon>
        <mat-icon *ngIf="!logged">more_horiz</mat-icon>
        <mat-menu #menuAccount="matMenu">
          <button *ngFor="let item of menuListAccount" mat-menu-item>
            <a routerLink="{{item.routerLink}}" class="mdl-navigation__link" href="">
              <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
            </a>
          </button>
          <button *ngIf="logged" mat-menu-item (click)="logout()">
            <a class="mdl-navigation__link" href="">
              <mat-icon>logout</mat-icon>Logout
            </a>
          </button>
        </mat-menu>
        <span *ngIf="(profile$ | async) as profile">
          {{profile?.name}}
        </span>
      </button>

    </div>
  </header>

  <main class="mdl-layout__content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <simple-notifications [options]="optionsForNotifications"></simple-notifications>
  </main>
</div>