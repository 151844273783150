
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { VarietySynonym } from '../entities/varietysynonym/varietysynonym.model';
import { VarietySynonymService } from '../entities/varietysynonym/services/varietysynonym.service';
import { VarietyService } from '../entities/variety/services/variety.service';


export interface VarietySynonymExt extends VarietySynonym {
  variety?: string;
}


@Component({
  selector: 'app-variety-synonym-table',
  templateUrl: './variety-synonym-table.component.html',
  styleUrls: ['./variety-synonym-table.component.css']
})
export class VarietySynonymTableComponent implements AfterViewInit {

  public filterText: string = null;
  public varietiesData = new Map<number, string>();

  title = "Variety -> Synonym";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: VarietySynonymExt) => `${row.id ? row.id : ""}` },
    { columnDef: 'variety', header: 'Variety', cell: (row: VarietySynonymExt) => `${row.variety ? row.variety : ""}` },
    { columnDef: 'synonym', header: 'Synonym', cell: (row: VarietySynonymExt) => `${row.synonym ? row.synonym : ""}` },
  ];

displayedColumns = [
  "id",
  "variety",
  "synonym",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _varietysynonymService: VarietySynonymService,
    private _varietyService: VarietyService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngOnInit() {

    const p0 = this._varietyService.getAllItems().toPromise();

    Promise.all([p0]).then((data) => {
      data[0].forEach((item) => this.varietiesData.set(item["value"], item["label"]));
      this.loadDefaultTable();
    });

  }
  
  ngAfterViewInit() {}
  
  loadDefaultTable() {

    this._varietysynonymService.getAll().subscribe((data) => {

      data.forEach((item) => {
        item["variety"] = this.varietiesData.get(item.varietyId);
      });

      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["varietysynonym-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["varietysynonym-form", row.id]);
  }



}
