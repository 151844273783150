
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { VendorI18n } from '../vendori18n.model';
import { VendorI18nService } from '../services/vendori18n.service';



@Component({
  selector: 'app-vendori18n-table',
  templateUrl: './vendori18n-table.component.html',
  styleUrls: ['./vendori18n-table.component.css']
})
export class VendorI18nTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "VendorI18n Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: VendorI18n) => `${row.id ? row.id : ""}` },
    { columnDef: 'languageId', header: 'LanguageId', cell: (row: VendorI18n) => `${row.languageId ? row.languageId : ""}` },
    { columnDef: 'vendorId', header: 'VendorId', cell: (row: VendorI18n) => `${row.vendorId ? row.vendorId : ""}` },
    { columnDef: 'name', header: 'Name', cell: (row: VendorI18n) => `${row.name ? row.name : ""}` },
    { columnDef: 'description', header: 'Description', cell: (row: VendorI18n) => `${row.description ? row.description : ""}` },
  ];

displayedColumns = [
  "id",
  "languageId",
  "vendorId",
  "name",
  "description",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _vendori18nService: VendorI18nService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._vendori18nService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["vendori18n-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["vendori18n-form", row.id]);
  }



}
