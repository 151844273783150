// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { Region } from '../../entities/region/region.model';
import { RegionService } from '../../entities/region/services/region.service';

import { VarietyWithI18nService } from './variety-with-i18n.service';
import { VarietyI18nService } from 'src/app/entities/varietyi18n/services/varietyi18n.service';
import { VarietyI18n } from 'src/app/entities/varietyi18n/varietyi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface VarietyTableRow extends TableRow<VarietyI18n> {
  region: string;
}

export interface VarietyWithI18n {
	id: number; 
    regionId: number;
	name: string;
	description: string; 
	i18n: VarietyI18n[];
}

@Component({
  selector: 'app-variety-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class VarietyWithI18nComponent extends CommonFormComponent<VarietyWithI18n, VarietyI18n> implements AfterViewInit {

  foreignKeyFieldName = "varietyId";
  listRoute= "variety-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: VarietyTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: VarietyTableRow) => `${row.name}` },
    { columnDef: 'region', header: 'Region of origin', cell: (row: VarietyTableRow) =>  `${row.region }` },
  ];

  public title = 'Variety List';
  public entity = 'Variety';


  public regionList: Region[];
  public regionMap = new Map<number, Region>();


  constructor(
   	private _regionService: RegionService,
    _languageService: LanguageService,
    _varietyWithI18nService: VarietyWithI18nService,
    _varietyI18nService: VarietyI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _varietyWithI18nService, _varietyI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();

    forkJoin([
this._regionService.getAll(),
]).subscribe(([
regionList,
]) => {
      this.setRegionList(regionList);

      this.fetchDataAndLanguages();
    });

  }

  getTableRow(data: VarietyWithI18n): VarietyTableRow {
    let row = {} as VarietyTableRow;
    row = Object.assign(data, row);
    row.region = this.regionMap.get(data.regionId)?.name;
    return row;
  }

  setRegionList = (data: Region[]) => {
    this.regionList = data;
    data.forEach((item) => this.regionMap.set(item.id, item));
  }

}
