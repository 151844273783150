
<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">

      <mat-grid-list cols="1" rowHeight="80px">


	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.isoCode .value ">IsoCode</mat-label>
            <input matInput type="text" required  formControlName="isoCode">
	
	            <mat-error *ngIf="!form.controls.isoCode.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.nativeName .value ">NativeName</mat-label>
            <input matInput type="text" required  formControlName="nativeName">
	
	            <mat-error *ngIf="!form.controls.nativeName.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.englishName .value ">EnglishName</mat-label>
            <input matInput type="text" required  formControlName="englishName">
	
	            <mat-error *ngIf="!form.controls.englishName.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!language?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>