<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-16 ( Time 17:31:23 ) -->

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border" style="position: relative">
    <h2 class="mdl-card__title-text">Translations for <div style="padding-left:10px; color:yellowgreen">{{ entity }}</div></h2>
    <button mat-raised-button color="primary" (click)="list()" style="position: absolute; bottom: 10px; right: 10px;">
      Modify {{entity}} List <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
    </button>
  </div>

  <!-- <form [formGroup]="form" novalidate (ngSubmit)="save()"> -->

  <form [formGroup]="form" novalidate>

    <mat-grid-list cols="5" rowHeight="60px">

      <mat-grid-tile [colspan]="1" [rowspan]="10" style="align-items: none">
        <div class="list-card-body" style="margin-top: -100px">
          <!-- <button mat-stroked-button color="primary" style="margin-bottom: 20px;"
            (click)="btnClick('language')">Language</button>
          <mat-divider></mat-divider> -->
          <section style="margin: auto;" *ngFor="let nav of navigationList">
            <!-- <div class="example-label" style="padding-left: 20px;">{{nav.title}}</div> -->
            <!-- <div class="example-button-row"> -->
            <!-- <button mat-stroked-button color="primary" (click)="btnClick(nav.routerLink)">List</button> -->
            <!-- <button mat-stroked-button color="accent" (click)="btnClickI18n(nav.routerLink)">i18n</button> -->
            <button mat-stroked-button color="primary" (click)="btnClickViewForm(nav.routerLink)">{{nav.title}}</button>
            <!-- </div> -->
          </section>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4" [rowspan]="6">
        <cdk-virtual-scroll-viewport [itemSize]="10" class="example-viewport">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <tr mat-header-row *matHeaderRowDef="getColumnsIds();  sticky: true;" style="height: 30px;"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumnsIds();" style="height: 40px;"
              [ngClass]="{'highlight': selectedRowIndex == row.id}" (click)="changeSelectedRow(row)"></tr>
            <ng-container *ngFor="let column of displayedColumnsDef" matColumnDef="{{column.columnDef}}">
              <th mat-header-cell *matHeaderCellDef >{{ column.header}}
              </th>
              <td mat-cell *matCellDef="let row">{{ column.cell(row) }} </td>
            </ng-container>
          </table>
        </cdk-virtual-scroll-viewport>

      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="2">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
          <mat-radio-button class="example-radio-button" (change)="changeLanguage($event)"
            *ngFor="let language of languages" [value]="language.id">
            {{language.englishName}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="4">
        <div class="add-edit-form">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput required formControlName="name">
          <mat-error *ngIf="!form.controls.name.valid">
            field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>

        <mat-form-field *ngFor="let field of extraFields"  appearance="outline">
          <mat-label>{{field.label}}</mat-label>
          <input matInput formControlName="{{field.name}}">
        </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="2">
        <div class="i18n-button-row">
          <button mat-raised-button color="primary" [disabled]="!(form.valid && form.dirty)"
            (click)="save()">Save</button>
          <button mat-raised-button color="warn" (click)="deleteConfirmation()">Delete</button>
        </div>
      </mat-grid-tile>

    </mat-grid-list>

  </form>

  <!-- </ng-template> -->

  <!-- </mat-tab>
  </mat-tab-group> -->

</div>