import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WlistService } from '../entities/wlist/services/wlist.service';
import { Wlist } from '../entities/wlist/wlist.model';
import { NotificationService } from '../services/notification.service';
import { NamedQueryService } from '../services/named-query.service';

@Component({
  selector: 'app-wine-list-page',
  templateUrl: './wine-list-page.component.html',
  styleUrls: ['./wine-list-page.component.css']
})
export class WineListPageComponent implements OnInit {

  lists: Wlist[];
  wineList: Wlist[];
  selectedList: Wlist;
  selectedWine: Wlist;
  public form: FormGroup;

  constructor(
    private _nqService: NamedQueryService,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public _router: Router,
    public _dialog: MatDialog) {
  }

  ngOnInit(): void {
    this._nqService.getNQArray(["wlist", "wine-lists"]).subscribe((data) => {
      this.lists = data as Wlist[];
    });
    this.form = this._formBuilder.group(this.getNewForm());
  }

  getNewForm = () => {
    return {
      name: ['', Validators.required]
    };
  }

  selectList(lista) {
    this.selectedList = lista;
    this._nqService.getNQArray(["wlist", "wine-list-content", lista.id]).subscribe((data) => {
      this.wineList = data as Wlist[];
    }, (error) => this.manageError(error));
  }

  selectWine(wine) {
    this.selectedWine = wine;
  }

  createNewList() {
    const listName = this.form.controls.name.value;
    this._nqService.getNQArray(["wlist", "add-wine-list", listName]).subscribe((response) => {
      console.log(response);
      this.lists = response as Wlist[];
    }, (error) => this.manageError(error));
  }

  deleteSelectedList() {
    const listId = this.selectedList.id;
    this._nqService.getNQArray(["wlist", "delete-wine-list", listId]).subscribe((response) => {
      console.log(response);
      this.lists = response as Wlist[];
    }, (error) => this.manageError(error));
  }

  deleteSelectedWine() {
    const relId = this.selectedWine.id;
    this._nqService.getNQArray(["wlist", "delete-wine-from-list", relId]).subscribe((response) => {
      console.log(response);
      this.wineList = response as Wlist[];
    }, (error) => this.manageError(error));
  }

  manageError(error) {
    this._notificationService.error("Deletion Error", error._body);
  }

}
