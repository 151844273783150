
<!-- File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">

      <mat-grid-list cols="2" rowHeight="80px">


	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.type .value ">Type</mat-label>
            <input matInput type="text" required  formControlName="type">
	
	            <mat-error *ngIf="!form.controls.type.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.name .value ">Name</mat-label>
            <input matInput type="text" required  formControlName="name">
	
	            <mat-error *ngIf="!form.controls.name.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.email .value ">Email</mat-label>
            <input matInput type="text" required  formControlName="email">
	
	            <mat-error *ngIf="!form.controls.email.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.password .value ">Password</mat-label>
            <input matInput type="text"    formControlName="password">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.imageUrl .value ">ImageUrl</mat-label>
            <input matInput type="text"    formControlName="imageUrl">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.imageBase64 .value ">ImageBase64</mat-label>
            <input matInput type="text"    formControlName="imageBase64">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-checkbox formControlName="emailVerified"   color="primary">EmailVerified</mat-checkbox>
        </mat-grid-tile>




        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-checkbox formControlName="enabled"   color="primary">Enabled</mat-checkbox>
        </mat-grid-tile>




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.provider .value ">Provider</mat-label>
            <input matInput type="text"    formControlName="provider">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.providerId .value ">ProviderId</mat-label>
            <input matInput type="text"    formControlName="providerId">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.createdAt .value ">CreatedAt</mat-label>
            <input matInput type="date"    formControlName="createdAt">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.updatedAt .value ">UpdatedAt</mat-label>
            <input matInput type="date"    formControlName="updatedAt">
	
	          </mat-form-field>
        </mat-grid-tile>	




        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <mat-label [class.red]="!form.controls.language .value ">Language</mat-label>
            <input matInput type="text" required  formControlName="language">
	
	            <mat-error *ngIf="!form.controls.language.valid">
              field is required
            </mat-error>
	          </mat-form-field>
        </mat-grid-tile>	
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!account?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>