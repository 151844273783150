<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">

    <mat-grid-list cols="6" rowHeight="50px">

        <mat-grid-tile [colspan]="2" [rowspan]="8">
            <mat-grid-tile-header>My Wine Lists</mat-grid-tile-header>
            <mat-action-list>
                <cdk-virtual-scroll-viewport [itemSize]="10" class="example-viewport">
                    <button mat-list-item *ngFor="let list of lists" (click)="selectList(list)">{{list.name}}
                    </button>
                </cdk-virtual-scroll-viewport>
            </mat-action-list>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="4" [rowspan]="8">
            <mat-grid-tile-header>List of wines</mat-grid-tile-header>
            <mat-action-list>
                <cdk-virtual-scroll-viewport [itemSize]="10" class="example-viewport">
                    <!-- <button mat-list-item *ngFor="let wine of wineList" (dblclick)="wineDetails(wine)" -->
                    <button mat-list-item *ngFor="let wine of wineList" 
                        (click)="selectWine(wine)">{{wine.name}}</button>
                </cdk-virtual-scroll-viewport>
            </mat-action-list>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="1">
            <form>
                <button mat-raised-button color="warn" (click)="deleteSelectedList()"
                    [disabled]="selectedList == undefined">Delete Selected List</button>
            </form>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="4" [rowspan]="1">
            <form>
                <button mat-raised-button color="warn" (click)="deleteSelectedWine()"
                    [disabled]="selectedWine == undefined">Delete Selected Wine</button>
            </form>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="3" [rowspan]="3">

            <form id="newList" [formGroup]="form" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput required formControlName="name">
                    <mat-error *ngIf="!form.controls.name.valid">
                        field is required
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="primary" [disabled]="!(form.valid && form.dirty)"
                    (click)="createNewList()">Create New List</button>

            </form>

        </mat-grid-tile>

    </mat-grid-list>

</div>