<div class="material-card-narrow mdl-card mdl-shadow--2dp form-card">

  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">Sign In</h2>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="submit()">

    <div class="add-edit-form">

      <mat-grid-list cols="1" rowHeight="80px">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <input type="email" matInput placeholder="email" required formControlName="email">
            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.email.errors.required">Email is required.</mat-error>
              <mat-error *ngIf="f.email.errors.email">
                Please, enter valid email address.
              </mat-error>
            </div>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
            <input type="password" matInput placeholder="password" required formControlName="password">
            <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.password.errors.required">
                Password is required.
              </mat-error>
              <mat-error *ngIf="f.password.errors.minlength">
                Password should be at least 8 character.
              </mat-error>
            </div>

          </mat-form-field>

        </mat-grid-tile>
      </mat-grid-list>

    </div>

    <div class="mdl-card__actions mdl-card--border">
      <div class="card-footer-button form-button">
        <button class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button" [disabled]="!form.valid"
          type="submit">Submit</button>
      </div>
    </div>

  </form>
</div>