// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-15 ( Time 19:48:56 )

import { GenericService } from './../../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AppellationOenologistRel } from '../appellationoenologistrel.model';
import { Configuration } from '../../../app.configuration';

@Injectable()
export class AppellationOenologistRelService extends GenericService<AppellationOenologistRel>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'appellationoenologistrel/');
    }

}
