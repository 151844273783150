// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-17 ( Time 09:49:48 )

import { Injectable, EventEmitter } from '@angular/core';

/**
 * Event service for communication between angular components.
 */
@Injectable()
export class EmitterService {

    // Event store
    private static _emitters: { [ID: string]: EventEmitter<any> } = {};

    // Set a new event in the store with a given ID
    // as key
    static get(ID: string): EventEmitter<any> {
        if (!this._emitters[ID]) {
            this._emitters[ID] = new EventEmitter();
        }
        return this._emitters[ID];
    }
}
