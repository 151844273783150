// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-24 ( Time 18:21:26 )

import { GenericService } from './generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Configuration } from '../app.configuration';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'underscore';


export interface NQWlist {
	id: number; // Wrapper Type : Number
	name: string; // Wrapper Type : String
}

@Injectable()
export class NamedQueryService extends GenericService<any>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'nq/');
    }

}
