import { Component, OnInit } from "@angular/core";
import { signup, login } from "../util/APIUtils";
import { NotificationService } from "./../services/notification.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import {
  ACCESS_TOKEN,
} from "../constants";
import { Producer } from "../entities/producer/producer.model";
import { ProducerService } from "../entities/producer/services/producer.service";
import { Vendor } from "../entities/vendor/vendor.model";
import { VendorService } from "../entities/vendor/services/vendor.service";

import { LanguageService } from "../entities/language/services/language.service";
import { Language } from "../entities/language/language.model";
import { ListItem } from "../services/generic.service";
import { NamedQueryService } from "../services/named-query.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {

  accountTypes: any = ["Admin", "User", "Producer", "Vendor"];
  producers: ListItem[];
  vendors: ListItem[];
  languages: Language[];

  form = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
    ]),
    matchingPassword: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
    ]),
    type: new FormControl("", []),
    language: new FormControl("", [Validators.required]),
    producer: new FormControl("", []),
    vendor: new FormControl("", [])
  });

  get f() {
    return this.form.controls;
  }

  constructor(
    private _vendorService: VendorService,
    private _producerService: ProducerService,
    private _nqService: NamedQueryService,
    private _languageService: LanguageService,
    private _router: Router,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.loadLanguages();
  }

  submit() {

    const registerRequest = Object.assign({}, this.form.value);
    const accountType = registerRequest.type;
    registerRequest.type = accountType.charAt(0);

    signup(registerRequest)
      .then((account) => {
        this._notificationService.success(
          "Success",
          "Account has been successfully registered!"
        );

        if (registerRequest.vendor) {
          this._nqService.getNQSingle(["vendor", "link-account-to-vendor", account.data.id, registerRequest.vendor]).subscribe((response) => console.log(response));
        }

        if (registerRequest.producer) {
          this._nqService.getNQSingle(["producer", "link-account-to-producer", account.data.id, registerRequest.producer]).subscribe((response) => console.log(response));
        }

        //devo aggiornare il record 
        this._router.navigate([""]);
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
        );
        this._notificationService.error("Error", error.message);
      });
  }


  loadLanguages() {
    this._languageService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  loadAccounts(selectedValue) {
    if (selectedValue == "Vendor" && !this.vendors) {
      this.form.controls.vendor.reset;
      this._vendorService.getAllItems().subscribe((values) => {
        this.vendors = values;
      });
    } else if (selectedValue == "Producer" && !this.producers) {
      this.form.controls.producer.reset;
      this._producerService.getAllItems().subscribe((values) => {
        this.producers = values;
      });
    }
  }
}
