<mat-card class="blog mat-elevation-z3">

  <mat-card-title>{{data.title}}</mat-card-title>

  <mat-card-content>
    <form [formGroup]="myForm">

      <div id="toolbarDiv"></div>
      <div class="mat-form-field-wrapper">

        <editor apiKey="nxgxwrbpjek5oh85n6ozd1oh06bkazfp72nhbxycpp7rzowf" [formControl]="myForm.controls.body"
          class="mat-elevation-z1" (onInit)="handleEditorInit($event)" [init]="{
              icons: 'material',
              skin: 'borderless',
              menubar: false,
              min_height: 350,
              fixed_toolbar_container: 'toolbarDiv',
              toolbar_mode: 'wrap'
            }"></editor>

        <div class="mat-form-field-subscript-wrapper">
          <mat-error *ngIf="myForm.controls.body.hasError('maxlength')">
            Your HTML content exceeds exceeds the character limit
            {{ myForm.controls.body.getError('maxlength').actualLength }} /
            {{ myForm.controls.body.getError('maxlength').requiredLength }}
          </mat-error>

          <!-- <mat-error *ngIf="myForm.controls.body.touched && myForm.controls.body.hasError('required')">
            This form is required
          </mat-error> -->
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-raised-button (click)="onNoClick()">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!myForm.valid">
      Submit
    </button>
  </mat-card-actions>

</mat-card>