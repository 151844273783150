// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-06-06 ( Time 10:06:26 )

import { GenericService } from './../../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { VendorI18n } from '../vendori18n.model';
import { Configuration } from '../../../app.configuration';

@Injectable()
export class VendorI18nService extends GenericService<VendorI18n>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'vendori18n/');
    }

}
