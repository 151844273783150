// Modules imports
import { Component } from '@angular/core';
import { WineExt } from '../wine-view/wine-view.component';
import { WinesTableComponent } from './wines-table.component';
import { CONTENT_MODE } from './wines-table.component';


@Component({
  selector: 'app-wines-proposals-table',
  templateUrl: './wines-table.component.html',
  styleUrls: ['./wines-table.component.css']
})
export class WinesProposalsTableComponent extends WinesTableComponent {

  // Producer Select
  title = "Wine Proposals";
  contentMode = CONTENT_MODE.PROPOSAL;

  ngOnInit() {
    this.displayedColumnsDef.push({ columnDef: 'approved', header: 'Approved', cell: (row: WineExt) => `${row["approved"]}` });
    this.displayedColumns.push("viewItem");
    this.displayedColumns.push("approved");
    super.ngOnInit();
  }

}

