// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-31 ( Time 13:51:02 )

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';

//custom imports
import { customDeclarations, customModules, customServices } from './custom_app.module';

// Routing
import { routing } from './app.routing';

// Account Components
import { AccountTableComponent } from './entities/account/account-table/account-table.component';
import { AccountFormComponent } from './entities/account/account-form/account-form.component';

// Appellation Components
import { AppellationTableComponent } from './entities/appellation/appellation-table/appellation-table.component';
import { AppellationFormComponent } from './entities/appellation/appellation-form/appellation-form.component';

// AppellationI18n Components
import { AppellationI18nTableComponent } from './entities/appellationi18n/appellationi18n-table/appellationi18n-table.component';
import { AppellationI18nFormComponent } from './entities/appellationi18n/appellationi18n-form/appellationi18n-form.component';

// AppellationOenologistRel Components
import { AppellationOenologistRelTableComponent } from './entities/appellationoenologistrel/appellationoenologistrel-table/appellationoenologistrel-table.component';
import { AppellationOenologistRelFormComponent } from './entities/appellationoenologistrel/appellationoenologistrel-form/appellationoenologistrel-form.component';

// AppellationVarietyRel Components
import { AppellationVarietyRelTableComponent } from './entities/appellationvarietyrel/appellationvarietyrel-table/appellationvarietyrel-table.component';
import { AppellationVarietyRelFormComponent } from './entities/appellationvarietyrel/appellationvarietyrel-form/appellationvarietyrel-form.component';

// Award Components
import { AwardTableComponent } from './entities/award/award-table/award-table.component';
import { AwardFormComponent } from './entities/award/award-form/award-form.component';

// AwardI18n Components
import { AwardI18nTableComponent } from './entities/awardi18n/awardi18n-table/awardi18n-table.component';
import { AwardI18nFormComponent } from './entities/awardi18n/awardi18n-form/awardi18n-form.component';

// Certification Components
import { CertificationTableComponent } from './entities/certification/certification-table/certification-table.component';
import { CertificationFormComponent } from './entities/certification/certification-form/certification-form.component';

// CertificationI18n Components
import { CertificationI18nTableComponent } from './entities/certificationi18n/certificationi18n-table/certificationi18n-table.component';
import { CertificationI18nFormComponent } from './entities/certificationi18n/certificationi18n-form/certificationi18n-form.component';

// Colour Components
import { ColourTableComponent } from './entities/colour/colour-table/colour-table.component';
import { ColourFormComponent } from './entities/colour/colour-form/colour-form.component';

// ColourI18n Components
import { ColourI18nTableComponent } from './entities/colouri18n/colouri18n-table/colouri18n-table.component';
import { ColourI18nFormComponent } from './entities/colouri18n/colouri18n-form/colouri18n-form.component';

// Country Components
import { CountryTableComponent } from './entities/country/country-table/country-table.component';
import { CountryFormComponent } from './entities/country/country-form/country-form.component';

// CountryI18n Components
import { CountryI18nTableComponent } from './entities/countryi18n/countryi18n-table/countryi18n-table.component';
import { CountryI18nFormComponent } from './entities/countryi18n/countryi18n-form/countryi18n-form.component';

// Dryness Components
import { DrynessTableComponent } from './entities/dryness/dryness-table/dryness-table.component';
import { DrynessFormComponent } from './entities/dryness/dryness-form/dryness-form.component';

// DrynessI18n Components
import { DrynessI18nTableComponent } from './entities/drynessi18n/drynessi18n-table/drynessi18n-table.component';
import { DrynessI18nFormComponent } from './entities/drynessi18n/drynessi18n-form/drynessi18n-form.component';

// Effervescence Components
import { EffervescenceTableComponent } from './entities/effervescence/effervescence-table/effervescence-table.component';
import { EffervescenceFormComponent } from './entities/effervescence/effervescence-form/effervescence-form.component';

// EffervescenceI18n Components
import { EffervescenceI18nTableComponent } from './entities/effervescencei18n/effervescencei18n-table/effervescencei18n-table.component';
import { EffervescenceI18nFormComponent } from './entities/effervescencei18n/effervescencei18n-form/effervescencei18n-form.component';

// Language Components
import { LanguageTableComponent } from './entities/language/language-table/language-table.component';
import { LanguageFormComponent } from './entities/language/language-form/language-form.component';

// Oenologist Components
import { OenologistTableComponent } from './entities/oenologist/oenologist-table/oenologist-table.component';
import { OenologistFormComponent } from './entities/oenologist/oenologist-form/oenologist-form.component';

// OenologistI18n Components
import { OenologistI18nTableComponent } from './entities/oenologisti18n/oenologisti18n-table/oenologisti18n-table.component';
import { OenologistI18nFormComponent } from './entities/oenologisti18n/oenologisti18n-form/oenologisti18n-form.component';

// Producer Components
import { ProducerTableComponent } from './entities/producer/producer-table/producer-table.component';
import { ProducerFormComponent } from './entities/producer/producer-form/producer-form.component';

// ProducerI18n Components
import { ProducerI18nTableComponent } from './entities/produceri18n/produceri18n-table/produceri18n-table.component';
import { ProducerI18nFormComponent } from './entities/produceri18n/produceri18n-form/produceri18n-form.component';

// Region Components
import { RegionTableComponent } from './entities/region/region-table/region-table.component';
import { RegionFormComponent } from './entities/region/region-form/region-form.component';

// RegionI18n Components
import { RegionI18nTableComponent } from './entities/regioni18n/regioni18n-table/regioni18n-table.component';
import { RegionI18nFormComponent } from './entities/regioni18n/regioni18n-form/regioni18n-form.component';

// Review Components
import { ReviewTableComponent } from './entities/review/review-table/review-table.component';
import { ReviewFormComponent } from './entities/review/review-form/review-form.component';

// ReviewI18n Components
import { ReviewI18nTableComponent } from './entities/reviewi18n/reviewi18n-table/reviewi18n-table.component';
import { ReviewI18nFormComponent } from './entities/reviewi18n/reviewi18n-form/reviewi18n-form.component';

// Variety Components
import { VarietyTableComponent } from './entities/variety/variety-table/variety-table.component';
import { VarietyFormComponent } from './entities/variety/variety-form/variety-form.component';

// VarietyI18n Components
import { VarietyI18nTableComponent } from './entities/varietyi18n/varietyi18n-table/varietyi18n-table.component';
import { VarietyI18nFormComponent } from './entities/varietyi18n/varietyi18n-form/varietyi18n-form.component';

// VarietySynonym Components
import { VarietySynonymTableComponent } from './entities/varietysynonym/varietysynonym-table/varietysynonym-table.component';
import { VarietySynonymFormComponent } from './entities/varietysynonym/varietysynonym-form/varietysynonym-form.component';

// Vendor Components
import { VendorTableComponent } from './entities/vendor/vendor-table/vendor-table.component';
import { VendorFormComponent } from './entities/vendor/vendor-form/vendor-form.component';

// VendorI18n Components
import { VendorI18nTableComponent } from './entities/vendori18n/vendori18n-table/vendori18n-table.component';
import { VendorI18nFormComponent } from './entities/vendori18n/vendori18n-form/vendori18n-form.component';

// Wine Components
import { WineTableComponent } from './entities/wine/wine-table/wine-table.component';
import { WineFormComponent } from './entities/wine/wine-form/wine-form.component';

// WineI18n Components
import { WineI18nTableComponent } from './entities/winei18n/winei18n-table/winei18n-table.component';
import { WineI18nFormComponent } from './entities/winei18n/winei18n-form/winei18n-form.component';

// WineOenologistRel Components
import { WineOenologistRelTableComponent } from './entities/wineoenologistrel/wineoenologistrel-table/wineoenologistrel-table.component';
import { WineOenologistRelFormComponent } from './entities/wineoenologistrel/wineoenologistrel-form/wineoenologistrel-form.component';

// WineVarietyRel Components
import { WineVarietyRelTableComponent } from './entities/winevarietyrel/winevarietyrel-table/winevarietyrel-table.component';
import { WineVarietyRelFormComponent } from './entities/winevarietyrel/winevarietyrel-form/winevarietyrel-form.component';

// WineWlistRel Components
import { WineWlistRelTableComponent } from './entities/winewlistrel/winewlistrel-table/winewlistrel-table.component';
import { WineWlistRelFormComponent } from './entities/winewlistrel/winewlistrel-form/winewlistrel-form.component';

// Wlist Components
import { WlistTableComponent } from './entities/wlist/wlist-table/wlist-table.component';
import { WlistFormComponent } from './entities/wlist/wlist-form/wlist-form.component';

// Other Components
import { NotFoundComponent } from './not-found/not-found.component';
import { AboutComponent } from './about/about.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { HomeComponent } from './home/home.component';

// Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ScrollingModule } from '@angular/cdk/scrolling';

// Services
import { Configuration } from './app.configuration';
import { AccountService } from './entities/account/services/account.service';
import { AppellationService } from './entities/appellation/services/appellation.service';
import { AppellationI18nService } from './entities/appellationi18n/services/appellationi18n.service';
import { AppellationOenologistRelService } from './entities/appellationoenologistrel/services/appellationoenologistrel.service';
import { AppellationVarietyRelService } from './entities/appellationvarietyrel/services/appellationvarietyrel.service';
import { AwardService } from './entities/award/services/award.service';
import { AwardI18nService } from './entities/awardi18n/services/awardi18n.service';
import { CertificationService } from './entities/certification/services/certification.service';
import { CertificationI18nService } from './entities/certificationi18n/services/certificationi18n.service';
import { ColourService } from './entities/colour/services/colour.service';
import { ColourI18nService } from './entities/colouri18n/services/colouri18n.service';
import { CountryService } from './entities/country/services/country.service';
import { CountryI18nService } from './entities/countryi18n/services/countryi18n.service';
import { DrynessService } from './entities/dryness/services/dryness.service';
import { DrynessI18nService } from './entities/drynessi18n/services/drynessi18n.service';
import { EffervescenceService } from './entities/effervescence/services/effervescence.service';
import { EffervescenceI18nService } from './entities/effervescencei18n/services/effervescencei18n.service';
import { LanguageService } from './entities/language/services/language.service';
import { OenologistService } from './entities/oenologist/services/oenologist.service';
import { OenologistI18nService } from './entities/oenologisti18n/services/oenologisti18n.service';
import { ProducerService } from './entities/producer/services/producer.service';
import { ProducerI18nService } from './entities/produceri18n/services/produceri18n.service';
import { RegionService } from './entities/region/services/region.service';
import { RegionI18nService } from './entities/regioni18n/services/regioni18n.service';
import { ReviewService } from './entities/review/services/review.service';
import { ReviewI18nService } from './entities/reviewi18n/services/reviewi18n.service';
import { VarietyService } from './entities/variety/services/variety.service';
import { VarietyI18nService } from './entities/varietyi18n/services/varietyi18n.service';
import { VarietySynonymService } from './entities/varietysynonym/services/varietysynonym.service';
import { VendorService } from './entities/vendor/services/vendor.service';
import { VendorI18nService } from './entities/vendori18n/services/vendori18n.service';
import { WineService } from './entities/wine/services/wine.service';
import { WineI18nService } from './entities/winei18n/services/winei18n.service';
import { WineOenologistRelService } from './entities/wineoenologistrel/services/wineoenologistrel.service';
import { WineVarietyRelService } from './entities/winevarietyrel/services/winevarietyrel.service';
import { WineWlistRelService } from './entities/winewlistrel/services/winewlistrel.service';
import { WlistService } from './entities/wlist/services/wlist.service';
import { EmitterService } from './services/emitter.service';
import { NotificationService } from './services/notification.service';
import { PagerService } from './services/pager.service';

const materialModules = [
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatNativeDateModule,
  ScrollingModule,
  ...customModules
]


@NgModule({
  declarations: [
    AppComponent,
    AccountTableComponent,
    AccountFormComponent,
    AppellationTableComponent,
    AppellationFormComponent,
    AppellationI18nTableComponent,
    AppellationI18nFormComponent,
    AppellationOenologistRelTableComponent,
    AppellationOenologistRelFormComponent,
    AppellationVarietyRelTableComponent,
    AppellationVarietyRelFormComponent,
    AwardTableComponent,
    AwardFormComponent,
    AwardI18nTableComponent,
    AwardI18nFormComponent,
    CertificationTableComponent,
    CertificationFormComponent,
    CertificationI18nTableComponent,
    CertificationI18nFormComponent,
    ColourTableComponent,
    ColourFormComponent,
    ColourI18nTableComponent,
    ColourI18nFormComponent,
    CountryTableComponent,
    CountryFormComponent,
    CountryI18nTableComponent,
    CountryI18nFormComponent,
    DrynessTableComponent,
    DrynessFormComponent,
    DrynessI18nTableComponent,
    DrynessI18nFormComponent,
    EffervescenceTableComponent,
    EffervescenceFormComponent,
    EffervescenceI18nTableComponent,
    EffervescenceI18nFormComponent,
    LanguageTableComponent,
    LanguageFormComponent,
    OenologistTableComponent,
    OenologistFormComponent,
    OenologistI18nTableComponent,
    OenologistI18nFormComponent,
    ProducerTableComponent,
    ProducerFormComponent,
    ProducerI18nTableComponent,
    ProducerI18nFormComponent,
    RegionTableComponent,
    RegionFormComponent,
    RegionI18nTableComponent,
    RegionI18nFormComponent,
    ReviewTableComponent,
    ReviewFormComponent,
    ReviewI18nTableComponent,
    ReviewI18nFormComponent,
    VarietyTableComponent,
    VarietyFormComponent,
    VarietyI18nTableComponent,
    VarietyI18nFormComponent,
    VarietySynonymTableComponent,
    VarietySynonymFormComponent,
    VendorTableComponent,
    VendorFormComponent,
    VendorI18nTableComponent,
    VendorI18nFormComponent,
    WineTableComponent,
    WineFormComponent,
    WineI18nTableComponent,
    WineI18nFormComponent,
    WineOenologistRelTableComponent,
    WineOenologistRelFormComponent,
    WineVarietyRelTableComponent,
    WineVarietyRelFormComponent,
    WineWlistRelTableComponent,
    WineWlistRelFormComponent,
    WlistTableComponent,
    WlistFormComponent,
    NotFoundComponent,
    AboutComponent,
    DeleteDialogComponent,
    HomeComponent,
    ...customDeclarations
],
  entryComponents: [
    DeleteDialogComponent
],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    routing,
    ...materialModules,
	BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot()
  ],
  exports: [
    ...materialModules,
  ],
  providers: [
    AccountService,
    AppellationService,
    AppellationI18nService,
    AppellationOenologistRelService,
    AppellationVarietyRelService,
    AwardService,
    AwardI18nService,
    CertificationService,
    CertificationI18nService,
    ColourService,
    ColourI18nService,
    CountryService,
    CountryI18nService,
    DrynessService,
    DrynessI18nService,
    EffervescenceService,
    EffervescenceI18nService,
    LanguageService,
    OenologistService,
    OenologistI18nService,
    ProducerService,
    ProducerI18nService,
    RegionService,
    RegionI18nService,
    ReviewService,
    ReviewI18nService,
    VarietyService,
    VarietyI18nService,
    VarietySynonymService,
    VendorService,
    VendorI18nService,
    WineService,
    WineI18nService,
    WineOenologistRelService,
    WineVarietyRelService,
    WineWlistRelService,
    WlistService,
    Configuration,
    EmitterService,
    NotificationService,
    PagerService,
    ...customServices
],
  bootstrap: [AppComponent]
})
export class AppModule { }
