// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { Award } from './../award.model';
import { AwardService } from './../services/award.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-award-form',
  templateUrl: './award-form.component.html',
  styleUrls: ['./award-form.component.css']
})
export class AwardFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'Award Form';
  public award: Award;
  public form: FormGroup;
  public ids;

  constructor(
    public _awardService: AwardService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (award?: Award) => {
    return {
      id: [{
          value: (award ? award.id : ''),
          disabled: true
      }],      name: [
        (award ? award.name : ''),
        Validators.required
      ],
      description: [
        (award ? award.description : '')
      ]
    };
  }


  load = () => {
    this._awardService.getSingle(this.ids).subscribe(
      (award: Award) => {
        this.award = {
          id: award.id,
          name: award.name,
          description: award.description
		};
        this.form = this._formBuilder.group(this.getNewForm(this.award));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a award, we are adding a award
    if (!this.award) {
      this.add();
    } else { // If we didn't get a award, we are adding a award
      this.update();
    }
  }

  add = () => {
    this._awardService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'Award added successfuly');
        this._router.navigate(['./award-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._awardService.update(<Award>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'Award edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.award.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `award: ${this.award.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.award.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._awardService.delete(id).subscribe(
      result => {
        this._router.navigate(['./award-table']);

        this._notificationService.success(
          'Deleted',
          `The award entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
