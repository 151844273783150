// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { CertificationWithI18nService } from './certification-with-i18n.service';
import { CertificationI18nService } from 'src/app/entities/certificationi18n/services/certificationi18n.service';
import { CertificationI18n } from 'src/app/entities/certificationi18n/certificationi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface CertificationTableRow extends TableRow<CertificationI18n> {
}

export interface CertificationWithI18n {
	id: number; 
	name: string;
	description: string; 
	i18n: CertificationI18n[];
}


@Component({
  selector: 'app-certification-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class CertificationWithI18nComponent extends CommonFormComponent<CertificationWithI18n, CertificationI18n> implements AfterViewInit {

  foreignKeyFieldName = "certificationId";
  listRoute= "certification-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: CertificationTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: CertificationTableRow) => `${row.name}` },
  ];

  public title = 'Certification List';
  public entity = 'Certification';



  constructor(
    _languageService: LanguageService,
    _certificationWithI18nService: CertificationWithI18nService,
    _certificationI18nService: CertificationI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _certificationWithI18nService, _certificationI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();


      this.fetchDataAndLanguages();

  }

  getTableRow(data: CertificationWithI18n): CertificationTableRow {
    let row = {} as CertificationTableRow;
    row = Object.assign(data, row);
    return row;
  }


}
