import { Component, OnInit } from "@angular/core";
import { resetPassword } from "../util/APIUtils";
import { NotificationService } from "../services/notification.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { PROFILE, ACCESS_TOKEN } from "../constants";
import { Account } from "../entities/account/account.model";
import * as _ from 'underscore';


@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {

  account: Account;
  public ids;

  form = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(8)
    ]),
    password1: new FormControl("", [
      Validators.required,
      Validators.minLength(8)
    ]),
  });

  getForm() {
    return this.form;
  }

  get fctrls() {
    return this.form.controls;
  }

  constructor(
    private _router: Router,
    public _route: ActivatedRoute,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this._route.params.subscribe(p => {
      this.ids = _.values(p);
    });

    if (localStorage.getItem(PROFILE)) {
      this.account = JSON.parse(localStorage.getItem(PROFILE));
      this.form.controls.email.setValue(this.account.email);
    }
  }

  submit() {

    const formValues = Object.assign({}, this.form.value);

    if (formValues.password != formValues.password1) {
      this._notificationService.error("Passwords Mismatch", "Please check your passwords fields");
      return;
    }

    var resetRequest = {};
    resetRequest["token"] = this.ids[0];
    resetRequest["newPassword"] = formValues.password;

    resetPassword(resetRequest)
      .then((response) => {
        this._notificationService.success(
          "Success",
          "Password has been resetted!"
        );
        this._router.navigate(["signin"]);
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
        );
        this._notificationService.error("Error", error.message);
      });
  }
}
