<div class="material-card-narrow mdl-card mdl-shadow--2dp form-card">

    <div class="mdl-card__title mdl-card--border">
        <h2 class="mdl-card__title-text">Reset Password</h2>
    </div>

    <form [formGroup]="form" novalidate (ngSubmit)="submit()">

        <div class="add-edit-form">

            <mat-grid-list cols="1" rowHeight="80px">

                <mat-grid-tile [hidden]="!fctrls.email.value" [colspan]="1" [rowspan]="1">
                    <mat-form-field appearance="outline" class="form-full-width">
                        <input disabled type="email" matInput placeholder="email" required formControlName="email">
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    <mat-form-field appearance="outline" class="form-full-width">
                        <mat-label [class.red]="!fctrls.password.value">Password</mat-label>
                        <input type="password" matInput required formControlName="password">
                        <div *ngIf="fctrls.password.touched && fctrls.password.invalid" class="alert alert-danger">
                            <mat-error *ngIf="fctrls.password.errors.required">
                                Password is required.
                            </mat-error>
                            <mat-error *ngIf="fctrls.password.errors.minlength">
                                Password should be at least 8 character.
                            </mat-error>
                        </div>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    <mat-form-field appearance="outline" class="form-full-width">
                        <mat-label [class.red]="!fctrls.password1.value">Retype password</mat-label>
                        <input type="password" matInput placeholder="password1" required formControlName="password1">
                        <div *ngIf="fctrls.password1.touched && fctrls.password1.invalid" class="alert alert-danger">
                            <mat-error *ngIf="fctrls.password1.errors.required">
                                Password is required.
                            </mat-error>
                            <mat-error *ngIf="fctrls.password1.errors.minlength">
                                Password should be at least 8 character.
                            </mat-error>
                        </div>
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>

        </div>

        <div class="mdl-card__actions mdl-card--border">
            <div class="card-footer-button form-button">
                <button class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button" [disabled]="!form.valid"
                    type="submit">Submit</button>
            </div>
        </div>

    </form>
</div>