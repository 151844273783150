// Modules imports
import { Component } from '@angular/core';
import { CONTENT_MODE, WinesTableComponent } from './wines-table.component';


@Component({
  selector: 'app-wines-search-table',
  templateUrl: './wines-table.component.html',
  styleUrls: ['./wines-table.component.css']
})
export class WinesSearchTableComponent extends WinesTableComponent {

  // Producer Select
  title = "Wine Search";
  contentMode = CONTENT_MODE.SEARCH;

  ngOnInit() {
    this.displayedColumns.push("rating");
    this.displayedColumns.push("viewItem");
    this.displayedColumns.push("addToList");
    this.displayedColumns.push("review");
    this.displayedColumns.push("clone");
    super.ngOnInit();
  }
}

