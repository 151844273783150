<h1 mat-dialog-title>Choice the wine list</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-select [(ngModel)]="selectedChoice">
            <mat-option *ngFor="let opt of options" [value]="opt.id">{{opt.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Back</button>
    <button mat-button color="primary" (click)="confirmSelection()" cdkFocusInitial>Save</button>
</div>
