// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { DrynessI18n } from './../drynessi18n.model';
import { DrynessI18nService } from './../services/drynessi18n.service';
import { Language } from './../../language/language.model';
import { LanguageService } from './../../language/services/language.service';
import { Dryness } from './../../dryness/dryness.model';
import { DrynessService } from './../../dryness/services/dryness.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-drynessi18n-form',
  templateUrl: './drynessi18n-form.component.html',
  styleUrls: ['./drynessi18n-form.component.css']
})
export class DrynessI18nFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'DrynessI18n Form';
  public drynessi18n: DrynessI18n;
  public form: FormGroup;
  public ids;

  // Language Select
  public languagesData: ListItem[];
  public selectedLanguageId: number;

  // Dryness Select
  public drynesssData: ListItem[];
  public selectedDrynessId: number;

  constructor(
    public _languageService: LanguageService,
    public _drynessService: DrynessService,
    public _drynessi18nService: DrynessI18nService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();
    this.fetchLanguages();
    this.fetchDrynesss();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  fetchLanguages = () => {
    this._languageService.getAllItems().subscribe(
      (data: ListItem[]) => this.languagesData = data,
      error => console.error(error));
  }

  fetchDrynesss = () => {
    this._drynessService.getAllItems().subscribe(
      (data: ListItem[]) => this.drynesssData = data,
      error => console.error(error));
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (drynessi18n?: DrynessI18n) => {
    return {
      id: [{
          value: (drynessi18n ? drynessi18n.id : ''),
          disabled: true
      }],      languageId: [
        (drynessi18n ? drynessi18n.languageId : ''),
        Validators.required
      ],
      drynessId: [
        (drynessi18n ? drynessi18n.drynessId : ''),
        Validators.required
      ],
      name: [
        (drynessi18n ? drynessi18n.name : ''),
        Validators.required
      ],
      description: [
        (drynessi18n ? drynessi18n.description : '')
      ]
    };
  }


  load = () => {
    this._drynessi18nService.getSingle(this.ids).subscribe(
      (drynessi18n: DrynessI18n) => {
        this.drynessi18n = {
          id: drynessi18n.id,
          languageId: drynessi18n.languageId,
          drynessId: drynessi18n.drynessId,
          name: drynessi18n.name,
          description: drynessi18n.description
		};
        this.form = this._formBuilder.group(this.getNewForm(this.drynessi18n));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a drynessi18n, we are adding a drynessi18n
    if (!this.drynessi18n) {
      this.add();
    } else { // If we didn't get a drynessi18n, we are adding a drynessi18n
      this.update();
    }
  }

  add = () => {
    this._drynessi18nService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'DrynessI18n added successfuly');
        this._router.navigate(['./drynessi18n-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._drynessi18nService.update(<DrynessI18n>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'DrynessI18n edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.drynessi18n.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `drynessi18n: ${this.drynessi18n.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.drynessi18n.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._drynessi18nService.delete(id).subscribe(
      result => {
        this._router.navigate(['./drynessi18n-table']);

        this._notificationService.success(
          'Deleted',
          `The drynessi18n entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
