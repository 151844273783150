import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NQWlist } from 'src/app/services/named-query.service';

@Component({
  selector: 'app-select-list-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.css']
})
export class SelectListDialogComponent implements OnInit {

  selectedChoice: string;

  constructor(
    public dialogRef: MatDialogRef<SelectListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: NQWlist[]) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmSelection(): void {
    this.dialogRef.close(this.selectedChoice)
  }

}
