// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-12-23 ( Time 11:35:22 )

// Modules imports
import { NotificationService } from './../../../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TinyEditorComponent } from 'src/app/util/tiny-editor/tiny-editor.component';

// Components imports
import { DeleteDialogComponent } from './../../../delete-dialog/delete-dialog.component';


// Models and services imports
import { ColourI18n } from './../colouri18n.model';
import { ColourI18nService } from './../services/colouri18n.service';
import { Language } from './../../language/language.model';
import { LanguageService } from './../../language/services/language.service';
import { Colour } from './../../colour/colour.model';
import { ColourService } from './../../colour/services/colour.service';
import { ListItem } from 'src/app/services/generic.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-colouri18n-form',
  templateUrl: './colouri18n-form.component.html',
  styleUrls: ['./colouri18n-form.component.css']
})
export class ColourI18nFormComponent implements OnInit {

  // HTTP status code
  readonly NOT_FOUND_ERROR = 404;
  readonly CONFLICT_ERROR = 409;
  readonly INTERNAL_SERVER_ERROR = 500;

  public title = 'ColourI18n Form';
  public colouri18n: ColourI18n;
  public form: FormGroup;
  public ids;

  // Language Select
  public languagesData: ListItem[];
  public selectedLanguageId: number;

  // Colour Select
  public coloursData: ListItem[];
  public selectedColourId: number;

  constructor(
    public _languageService: LanguageService,
    public _colourService: ColourService,
    public _colouri18nService: ColourI18nService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _notificationService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getIdFromRouteParams();
    this.fetchLanguages();
    this.fetchColours();

    this.initForm();

  }

  getIdFromRouteParams = () => {
    this._route.params.subscribe(p => {
        this.ids = _.values(p);
    });
  }

  fetchLanguages = () => {
    this._languageService.getAllItems().subscribe(
      (data: ListItem[]) => this.languagesData = data,
      error => console.error(error));
  }

  fetchColours = () => {
    this._colourService.getAllItems().subscribe(
      (data: ListItem[]) => this.coloursData = data,
      error => console.error(error));
  }

  initForm = () => {
    this.form = this._formBuilder.group(this.getNewForm());
    if (!_.isEmpty(this.ids)) {
      this.load();
    }
  }

  getNewForm = (colouri18n?: ColourI18n) => {
    return {
      id: [{
          value: (colouri18n ? colouri18n.id : ''),
          disabled: true
      }],      languageId: [
        (colouri18n ? colouri18n.languageId : ''),
        Validators.required
      ],
      colourId: [
        (colouri18n ? colouri18n.colourId : ''),
        Validators.required
      ],
      name: [
        (colouri18n ? colouri18n.name : ''),
        Validators.required
      ],
      description: [
        (colouri18n ? colouri18n.description : '')
      ]
    };
  }


  load = () => {
    this._colouri18nService.getSingle(this.ids).subscribe(
      (colouri18n: ColourI18n) => {
        this.colouri18n = {
          id: colouri18n.id,
          languageId: colouri18n.languageId,
          colourId: colouri18n.colourId,
          name: colouri18n.name,
          description: colouri18n.description
		};
        this.form = this._formBuilder.group(this.getNewForm(this.colouri18n));

      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  save = () => {
	// If we didn't get a colouri18n, we are adding a colouri18n
    if (!this.colouri18n) {
      this.add();
    } else { // If we didn't get a colouri18n, we are adding a colouri18n
      this.update();
    }
  }

  add = () => {
    this._colouri18nService.add(this.form.value).subscribe(
      result => {
        this._notificationService.success('Success', 'ColourI18n added successfuly');
        this._router.navigate(['./colouri18n-form', result.id]);
      },
      error => {1
        if (error.status === this.CONFLICT_ERROR) {
          this._notificationService.error(error.statusText, 'Id already used in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  update = () => {
    this._colouri18nService.update(<ColourI18n>this.form.getRawValue(), this.ids).subscribe(
      result => this._notificationService.success('Success', 'ColourI18n edited successfuly'),
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  deleteConfirmation = () => {
    if (this.colouri18n.id) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: `colouri18n: ${this.colouri18n.id}`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteBadge(this.colouri18n.id);
        }
      });
    }
  }

  deleteBadge = (id): void => {
    // Call delete service
    this._colouri18nService.delete(id).subscribe(
      result => {
        this._router.navigate(['./colouri18n-table']);

        this._notificationService.success(
          'Deleted',
          `The colouri18n entry with the id(s)='${id}' was deleted successfuly`);
      },
      error => {
        if (error.status === this.NOT_FOUND_ERROR) {
          this._notificationService.error(error.statusText, 'Entity not found in database');
        } else if (error.status === this.INTERNAL_SERVER_ERROR) {
          this._notificationService.error(error.statusText, error.json());
        } else {
          this._notificationService.error('Error', 'An error occured when trying to reach the server');
        }
      });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this.dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
      }
    });
  }

}
