
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { Variety } from '../variety.model';
import { VarietyService } from '../services/variety.service';



@Component({
  selector: 'app-variety-table',
  templateUrl: './variety-table.component.html',
  styleUrls: ['./variety-table.component.css']
})
export class VarietyTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "Variety Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: Variety) => `${row.id ? row.id : ""}` },
    { columnDef: 'regionId', header: 'RegionOfOriginId', cell: (row: Variety) => `${row.regionId ? row.regionId : ""}` },
    { columnDef: 'name', header: 'Name', cell: (row: Variety) => `${row.name ? row.name : ""}` },
    { columnDef: 'description', header: 'Description', cell: (row: Variety) => `${row.description ? row.description : ""}` },
  ];

displayedColumns = [
  "id",
  "regionId",
  "name",
  "description",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _varietyService: VarietyService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._varietyService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["variety-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["variety-form", row.id]);
  }



}
