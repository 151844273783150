
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { AppellationService } from '../entities/appellation/services/appellation.service';
import { AppellationOenologistRel } from '../entities/appellationoenologistrel/appellationoenologistrel.model';
import { AppellationOenologistRelService } from '../entities/appellationoenologistrel/services/appellationoenologistrel.service';
import { OenologistService } from '../entities/oenologist/services/oenologist.service';

export interface AppellationOenologistRelExt extends AppellationOenologistRel {
  oenologist?: string;
  appellation?: string;
}

@Component({
  selector: 'app-appellation-oenologist-table',
  templateUrl: './appellation-oenologist-table.component.html',
  styleUrls: ['./appellation-oenologist-table.component.css']
})
export class AppellationOenologistTableComponent implements AfterViewInit {

  public filterText: string = null;
  public oenologistsData = new Map<number, string>();
  public appellationsData = new Map<number, string>();

  title = "Appellation -> Oenologist";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: AppellationOenologistRel) => `${row.id ? row.id : ""}` },
    { columnDef: 'appellation', header: 'Appellation', cell: (row: AppellationOenologistRelExt) => `${row.appellation ? row.appellation : ""}` },
    { columnDef: 'oenologist', header: 'Oenologist', cell: (row: AppellationOenologistRelExt) => `${row.oenologist ? row.oenologist : ""}` },
  ];

  displayedColumns = [
    "id",
    "appellation",
    "oenologist"
  ];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    public _notificationService: NotificationService,
    private _oenologistService: OenologistService,
    private _appelationService: AppellationService,
    private _appellationoenologistrelService: AppellationOenologistRelService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngOnInit() {
    const p0 = this._oenologistService.getAllItems().toPromise();
    const p1 = this._appelationService.getAllItems().toPromise();

    Promise.all([p0, p1]).then((data) => {
      data[0].forEach((item) => this.oenologistsData.set(item["value"], item["label"]));
      data[1].forEach((item) => this.appellationsData.set(item["value"], item["label"]));
      this.loadDefaultTable();
    });
  }

  ngAfterViewInit() {}
  
  loadDefaultTable() {

    this._appellationoenologistrelService.getAll().subscribe((data) => {

      data.forEach((item) => {
        item["oenologist"] = this.oenologistsData.get(item.oenologistId);
        item["appellation"] = this.appellationsData.get(item.appellationId);
      });

      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["appellationoenologistrel-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["appellationoenologistrel-form", row.id]);
  }



}
