
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { Account } from '../account.model';
import { AccountService } from '../services/account.service';



@Component({
  selector: 'app-account-table',
  templateUrl: './account-table.component.html',
  styleUrls: ['./account-table.component.css']
})
export class AccountTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "Account Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: Account) => `${row.id ? row.id : ""}` },
    { columnDef: 'type', header: 'Type', cell: (row: Account) => `${row.type ? row.type : ""}` },
    { columnDef: 'name', header: 'Name', cell: (row: Account) => `${row.name ? row.name : ""}` },
    { columnDef: 'email', header: 'Email', cell: (row: Account) => `${row.email ? row.email : ""}` },
    { columnDef: 'password', header: 'Password', cell: (row: Account) => `${row.password ? row.password : ""}` },
    { columnDef: 'imageUrl', header: 'ImageUrl', cell: (row: Account) => `${row.imageUrl ? row.imageUrl : ""}` },
    { columnDef: 'imageBase64', header: 'ImageBase64', cell: (row: Account) => `${row.imageBase64 ? row.imageBase64 : ""}` },
    { columnDef: 'emailVerified', header: 'EmailVerified', cell: (row: Account) => `${row.emailVerified ? row.emailVerified : ""}` },
    { columnDef: 'enabled', header: 'Enabled', cell: (row: Account) => `${row.enabled ? row.enabled : ""}` },
    { columnDef: 'provider', header: 'Provider', cell: (row: Account) => `${row.provider ? row.provider : ""}` },
    { columnDef: 'providerId', header: 'ProviderId', cell: (row: Account) => `${row.providerId ? row.providerId : ""}` },
    { columnDef: 'createdAt', header: 'CreatedAt', cell: (row: Account) => `${row.createdAt ? row.createdAt : ""}` },
    { columnDef: 'updatedAt', header: 'UpdatedAt', cell: (row: Account) => `${row.updatedAt ? row.updatedAt : ""}` },
    { columnDef: 'language', header: 'Language', cell: (row: Account) => `${row.language ? row.language : ""}` },
  ];

displayedColumns = [
  "id",
  "type",
  "name",
  "email",
  "password",
  "imageUrl",
  "imageBase64",
  "emailVerified",
  "enabled",
  "provider",
  "providerId",
  "createdAt",
  "updatedAt",
  "language",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _accountService: AccountService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._accountService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["account-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["account-form", row.id]);
  }



}
