// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-17 ( Time 09:49:48 )

import { Configuration } from './../app.configuration';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ACCESS_TOKEN } from '../constants';
import { values } from 'underscore';


export class ListItem {
    value : number;
    label: string;
}

export abstract class GenericService<T> {

    protected headers: Headers;
    protected actionUrl: string;
    protected itemsUrl: string;

    constructor(private _http: Http, private _configuration: Configuration, private url: string) {
        this.actionUrl = _configuration.serverWithApiUrl + url;
        this.itemsUrl = _configuration.serverWithApiUrl + "items/" + url;
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
    }


    private setAccessToken = (): void => {
        if (localStorage.getItem(ACCESS_TOKEN)) {
            this.headers.set('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
        }
    }


    /**
     * Get all object by the GET Rest service
     * @return promise
     */
    public getAll = (): Observable<T[]> => {
        this.setAccessToken();
        return this._http.get(this.actionUrl, { headers: this.headers }).pipe(
            map((response: Response) => response.json() as T[]),
            catchError(this.handleError)
        );
    }

    public getNQArray = (keys: any[]): Observable<T[]> => {
        this.setAccessToken();
        return this._http.get(this.actionUrl + this.getKeysUrl(keys), { headers: this.headers }).pipe(
            map((response: Response) => {
                return response.json() as T[];
            }),
            catchError(this.handleError)
        );
    }

   
    public getNQSingle = (keys: any[]): Observable<T> => {
        this.setAccessToken();
        return this._http.get(this.actionUrl + this.getKeysUrl(keys), { headers: this.headers }).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError(this.handleError)
        );
    }

    public postNQSingle = (newT: any, keys: any[]): Observable<T> => {
        this.setAccessToken();
        return this._http.post(this.actionUrl + this.getKeysUrl(keys), JSON.stringify(newT), { headers: this.headers }).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError(this.handleError)
        );
    }
    /**
     * Get all object by the GET Rest service
     * @return promise
     */
    public getAllItems = (): Observable<ListItem[]> => {
        this.setAccessToken();
        return this._http.get(this.itemsUrl, { headers: this.headers }).pipe(
            map((response: Response) => response.json() as ListItem[]),
            catchError(this.handleError)
        );
    }



    /**
     * Get the object by the GET/:id Rest service
     * @param keys array of Identifiers
     * @return promise
     */
    public getSingle = (keys: any[]): Observable<T> => {
        this.setAccessToken();
        return this._http.get(this.actionUrl + this.getKeysUrl(keys), { headers: this.headers }).pipe(
            map((response: Response) => response.json() as T),
            catchError(this.handleError)
        );
    }

    /**
     * Add the object by the POST Rest service
     * @param newT The object to POST
     * @param t Object to update
     * @return promise
     */
    public add = (newT: T): Observable<T> => {
        this.setAccessToken();
        return this._http.post(this.actionUrl, JSON.stringify(newT), { headers: this.headers }).pipe(
            map((response: Response) => response.json() as T),
            catchError(this.handleError),
        );
    }

    /**
     * Update the object by the UPDATE Rest service
     * @param t Object to update
     * @param keys array of Identifiers
     * @return promise
     */
    public update = (t: T, keys: any[]): Observable<T> => {
        this.setAccessToken();
        return this._http.put(this.actionUrl + this.getKeysUrl(keys), JSON.stringify(t), { headers: this.headers }).pipe(
            map((response: Response) => response.json() as T),
            catchError(this.handleError)
        );
    }

    /**
     * Delete the object by the DELETE Rest service
     * @param keys array of Identifiers
     * @return promise
     */
    public delete = (...keys: any[]): Observable<Response> => {
        this.setAccessToken();
        return this._http.delete(this.actionUrl + this.getKeysUrl(keys), { headers: this.headers }).pipe(
            catchError(this.handleError)
        );
    }

    // Function to throw errors
    private handleError(error: Response) {
        return throwError(error);
    }

    /**
     * Function to return the url of many identifiers
     * @param keys array of Identifiers
     * @return promise
     */
    private getKeysUrl = (keys: any[]): string => {
        let idsUrl = '';

        keys.forEach(element => {
            idsUrl += `${element}/`;
        });

        return idsUrl;
    }
}
