// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { DrynessWithI18nService } from './dryness-with-i18n.service';
import { DrynessI18nService } from 'src/app/entities/drynessi18n/services/drynessi18n.service';
import { DrynessI18n } from 'src/app/entities/drynessi18n/drynessi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface DrynessTableRow extends TableRow<DrynessI18n> {
}

export interface DrynessWithI18n {
	id: number; 
	name: string;
	description: string; 
	i18n: DrynessI18n[];
}


@Component({
  selector: 'app-dryness-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class DrynessWithI18nComponent extends CommonFormComponent<DrynessWithI18n, DrynessI18n> implements AfterViewInit {

  foreignKeyFieldName = "drynessId";
  listRoute= "dryness-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: DrynessTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: DrynessTableRow) => `${row.name}` },
  ];

  public title = 'Dryness List';
  public entity = 'Dryness';



  constructor(
    _languageService: LanguageService,
    _drynessWithI18nService: DrynessWithI18nService,
    _drynessI18nService: DrynessI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _drynessWithI18nService, _drynessI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();


      this.fetchDataAndLanguages();

  }

  getTableRow(data: DrynessWithI18n): DrynessTableRow {
    let row = {} as DrynessTableRow;
    row = Object.assign(data, row);
    return row;
  }


}
