import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  //console.log("options", options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/account/me",
    method: "GET",
  });
}

export function logout() {
  return request({
    url: API_BASE_URL + "/account/logout",
    method: "GET",
  });
}

export function validateToken() {
  return request({
    url: API_BASE_URL + "/auth/validateToken",
    method: "GET",
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/login",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function resetPassword(resetRequest) {
  return request({
    url: API_BASE_URL + "/auth/resetPassword",
    method: "POST",
    body: JSON.stringify(resetRequest),
  });
}

export function changePassword(changeRequest) {
  return request({
    url: API_BASE_URL + "/account/changePassword",
    method: "POST",
    body: JSON.stringify(changeRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function confirmRegistration(token) {
  return request({
    url: API_BASE_URL + "/auth/confirmRegistration?token=" + token,
    method: "GET"
  });
}

export function updateProfile(updateProfileRequest) {
  return request({
    url: API_BASE_URL + "/account/updateProfile",
    method: "POST",
    body: JSON.stringify(updateProfileRequest),
  });
}


export function getProfile() {
  return request({
    url: API_BASE_URL + "/account/me",
    method: "GET"
  });
}

export function createWine(createWineRequest) {
  return request({
    url: API_BASE_URL + "/wine",
    method: "POST",
    body: JSON.stringify(createWineRequest),
  });
}
