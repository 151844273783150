// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';


import { AwardWithI18nService } from './award-with-i18n.service';
import { AwardI18nService } from 'src/app/entities/awardi18n/services/awardi18n.service';
import { AwardI18n } from 'src/app/entities/awardi18n/awardi18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface AwardTableRow extends TableRow<AwardI18n> {
}

export interface AwardWithI18n {
	id: number; 
	name: string;
	description: string; 
	i18n: AwardI18n[];
}


@Component({
  selector: 'app-award-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class AwardWithI18nComponent extends CommonFormComponent<AwardWithI18n, AwardI18n> implements AfterViewInit {

  foreignKeyFieldName = "awardId";
  listRoute= "award-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: AwardTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: AwardTableRow) => `${row.name}` },
  ];

  public title = 'Award List';
  public entity = 'Award';



  constructor(
    _languageService: LanguageService,
    _awardWithI18nService: AwardWithI18nService,
    _awardI18nService: AwardI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _awardWithI18nService, _awardI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();


      this.fetchDataAndLanguages();

  }

  getTableRow(data: AwardWithI18n): AwardTableRow {
    let row = {} as AwardTableRow;
    row = Object.assign(data, row);
    return row;
  }


}
