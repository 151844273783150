// File generated by Telosys Tools Generator ( version 3.1.2 ) - Date 2021-05-17 ( Time 09:49:47 )



import { GenericService } from './../../services/generic.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Configuration } from '../../app.configuration';
import { DrynessWithI18n } from './dryness-with-i18n.component';

@Injectable()
export class DrynessWithI18nService extends GenericService<DrynessWithI18n>  {

    /**
     * Constructor
     * @param _http Http
     * @param _configuration Configuration
     */
    constructor(_http: Http, _configuration: Configuration) {
        super(_http, _configuration, 'dryness-with-i18n/');
    }

}
