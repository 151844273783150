
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { WineOenologistRel } from '../wineoenologistrel.model';
import { WineOenologistRelService } from '../services/wineoenologistrel.service';



@Component({
  selector: 'app-wineoenologistrel-table',
  templateUrl: './wineoenologistrel-table.component.html',
  styleUrls: ['./wineoenologistrel-table.component.css']
})
export class WineOenologistRelTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "WineOenologistRel Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: WineOenologistRel) => `${row.id ? row.id : ""}` },
    { columnDef: 'wineId', header: 'WineId', cell: (row: WineOenologistRel) => `${row.wineId ? row.wineId : ""}` },
    { columnDef: 'oenologistId', header: 'OenologistId', cell: (row: WineOenologistRel) => `${row.oenologistId ? row.oenologistId : ""}` },
  ];

displayedColumns = [
  "id",
  "wineId",
  "oenologistId",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _wineoenologistrelService: WineOenologistRelService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._wineoenologistrelService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["wineoenologistrel-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["wineoenologistrel-form", row.id]);
  }



}
