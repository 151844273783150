
<!-- File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-01-31 ( Time 13:50:24 ) --> 

<div class="material-card-medium mdl-card mdl-shadow--2dp form-card">
  <div class="mdl-card__title mdl-card--border">
    <h2 class="mdl-card__title-text">{{ title }}</h2>
  </div>
  <form [formGroup]="form" novalidate>
    <div class="add-edit-form">


      <mat-grid-list cols="1" rowHeight="80px">
	

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.wineId .value ">wineId</mat-label>
	            <mat-error *ngIf="form.controls.wineId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="wineId" name="wine" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let wine of winesData" [value]="wine.value">
	              {{wine.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>

	        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-form-field appearance="outline" class="form-full-width">
          <mat-label [class.red]="!form.controls.oenologistId .value ">oenologistId</mat-label>
	            <mat-error *ngIf="form.controls.oenologistId.hasError('required')">
              field is required
            </mat-error>
	          <mat-select formControlName="oenologistId" name="oenologist" required [compareWith]="compareObjects" >
            <mat-option *ngFor="let oenologist of oenologistsData" [value]="oenologist.value">
	              {{oenologist.label}}
            </mat-option>
          </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div  id="buttonDiv" >
        <button mat-raised-button (click)="initForm()">
          Reset
        </button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">
          Save
        </button>
        <button mat-raised-button color="warn" [disabled]="!wineoenologistrel?.id" (click)="deleteConfirmation()">
          Delete
        </button>
    </div>
  </form>
  <div class="mdl-card__menu">

  </div>
</div>