import { Component, OnInit } from '@angular/core';
import { Review } from 'src/app/entities/review/review.model';
import { NamedQueryService } from 'src/app/services/named-query.service';
import { WineExt } from 'src/app/wine-view/wine-view.component';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.css']
})
export class ReviewListComponent implements OnInit {

  wine: WineExt;
  reviewCount: number;
  avgRating: number;
  reviews: Review[];

  constructor(
    public _nqService: NamedQueryService,
  ) { }

  ngOnInit(): void {
    this.wine = JSON.parse(localStorage.getItem("WINE"));
    this._nqService.getNQArray(["review", this.wine.id]).subscribe((reviews) => {
      this.reviews = reviews;
      this.reviewCount = reviews.length;
      var total = 0;
      for (var i = 0; i < reviews.length; i++) {
        total += reviews[i].rating;
      }
      this.avgRating = reviews.length > 0 ? total / reviews.length : 0;
    });
  }

}
