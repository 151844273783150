//Signin + register
import { ProfileComponent } from "./profile/profile.component";
import { RegisterComponent } from "./register/register.component";
import { SigninComponent } from "./signin/signin.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { RoleGuardService } from "./services/role-guard.service";

import { AwardWithI18nComponent } from "./dict-list/award-with-i18n/award-with-i18n.component";
import { AwardWithI18nService } from "./dict-list/award-with-i18n/award-with-i18n.service";

import { AppellationWithI18nComponent } from "./dict-list/appellation-with-i18n/appellation-with-i18n.component";
import { AppellationWithI18nService } from "./dict-list/appellation-with-i18n/appellation-with-i18n.service";

import { CertificationWithI18nComponent } from "./dict-list/certification-with-i18n/certification-with-i18n.component";
import { CertificationWithI18nService } from "./dict-list/certification-with-i18n/certification-with-i18n.service";

import { CountryWithI18nComponent } from "./dict-list/country-with-i18n/country-with-i18n.component";
import { CountryWithI18nService } from "./dict-list/country-with-i18n/country-with-i18n.service";

import { EffervescenceWithI18nService } from "./dict-list/effervescence-with-i18n/effervescence-with-i18n.service";

import { RegionWithI18nComponent } from "./dict-list/region-with-i18n/region-with-i18n.component";
import { RegionWithI18nService } from "./dict-list/region-with-i18n/region-with-i18n.service";

import { VarietyWithI18nComponent } from "./dict-list/variety-with-i18n/variety-with-i18n.component";
import { VarietyWithI18nService } from "./dict-list/variety-with-i18n/variety-with-i18n.service";

import { DrynessWithI18nComponent } from "./dict-list/dryness-with-i18n/dryness-with-i18n.component";
import { DrynessWithI18nService } from "./dict-list/dryness-with-i18n/dryness-with-i18n.service";

import { ColourWithI18nComponent } from "./dict-list/colour-with-i18n/colour-with-i18n.component";
import { ColourWithI18nService } from "./dict-list/colour-with-i18n/colour-with-i18n.service";
import { WineListPageComponent } from "./wine-list-page/wine-list-page.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TinyEditorComponent } from "./util/tiny-editor/tiny-editor.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EditorModule } from "@tinymce/tinymce-angular";

import { WineViewComponent } from './wine-view/wine-view.component';
import { SelectListDialogComponent } from "./wine-list-page/select-list-dialog/select-list-dialog.component";
import { DataStorage } from "./services/data-storage.provider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { WinesRecordsTableComponent } from "./wines-table/wines-records-table.component";
import { WinesProposalsTableComponent } from "./wines-table/wines-proposals-table.component";
import { WinesSearchTableComponent } from "./wines-table/wines-search-table.component";
import { WinesTableComponent } from "./wines-table/wines-table.component";
import { ProducerTableComponent } from "./entities/producer/producer-table/producer-table.component";
import { VendorTableComponent } from "./entities/vendor/vendor-table/vendor-table.component";
import { EffervescenceWithI18nComponent } from "./dict-list/effervescence-with-i18n/effervescence-with-i18n.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ConfirmRegistrationComponent } from "./reset-password/confirm-registration/confirm-registration.component";
import { WineEntryFormComponent } from "./wine-entry-form/wine-entry-form.component";
import { StarRatingComponent } from "./util/star-rating/star-rating.component";
import { ReviewEntryFormComponent } from "./review/review-entry-form/review-entry-form.component";
import { NamedQueryService } from "./services/named-query.service";
import { ReviewListComponent } from "./review/review-list/review-list.component";
import { VarietySynonymTableComponent } from './synonym-table/variety-synonym-table.component';
import { AppellationVarietyTableComponent } from './appellation-variety-table/appellation-variety-table.component';
import { AppellationOenologistTableComponent } from './appellation-oenologist-table/appellation-oenologist-table.component';
import { SearchByVarietiesDialogComponent } from "./search-by-varieties-dialog/search-by-varieties-dialog.component";

export const customDeclarations = [
  SigninComponent,
  RegisterComponent,
  ProfileComponent,

  //Dictionary I18n views
  AwardWithI18nComponent,
  AppellationWithI18nComponent,
  CertificationWithI18nComponent,
  ColourWithI18nComponent,
  CountryWithI18nComponent,
  DrynessWithI18nComponent,
  EffervescenceWithI18nComponent,
  RegionWithI18nComponent,
  VarietyWithI18nComponent,
  WinesTableComponent,
  WinesSearchTableComponent,
  WinesRecordsTableComponent,
  WinesProposalsTableComponent,
  WineListPageComponent,
  ProducerTableComponent,
  VendorTableComponent,
  WineViewComponent,
  WineEntryFormComponent,
  SelectListDialogComponent,
  SearchByVarietiesDialogComponent,
  TinyEditorComponent,
  ResetPasswordComponent,
  ConfirmRegistrationComponent,
  StarRatingComponent,
  ReviewEntryFormComponent,
  ReviewListComponent,
  VarietySynonymTableComponent,
  AppellationVarietyTableComponent,
  AppellationOenologistTableComponent
];

export const customServices = [
  AuthGuardService,
  AuthService,
  JwtHelperService,
  RoleGuardService,
  AwardWithI18nService,
  AppellationWithI18nService,
  CertificationWithI18nService,
  ColourWithI18nService,
  CountryWithI18nService,
  DrynessWithI18nService,
  EffervescenceWithI18nService,
  RegionWithI18nService,
  VarietyWithI18nService,
  DataStorage,
  NamedQueryService
];

export const customModules = [
  ScrollingModule,
  BrowserModule,
  EditorModule,
  FormsModule,
  MatInputModule,
  MatButtonModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule
];


