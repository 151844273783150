// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

import { LanguageService } from '../../entities/language/services/language.service';

import { Country } from '../../entities/country/country.model';
import { CountryService } from '../../entities/country/services/country.service';

import { RegionWithI18nService } from './region-with-i18n.service';
import { RegionI18nService } from 'src/app/entities/regioni18n/services/regioni18n.service';
import { RegionI18n } from 'src/app/entities/regioni18n/regioni18n.model';

import { CommonFormComponent, TableRow } from '../../util/common-with-i18n-form/common-with-i18n-form.component';
import { Router } from '@angular/router';


export interface RegionTableRow extends TableRow<RegionI18n> {
  country: string;
}

export interface RegionWithI18n {
	id: number; 
    countryId: number;
	name: string;
	description: string; 
	i18n: RegionI18n[];
}


@Component({
  selector: 'app-region-with-i18n',
  templateUrl: '../../util/common-with-i18n-form/common-with-i18n-form.component.html',
  styleUrls: ['../../util/common-with-i18n-form/common-with-i18n-form.component.css']
})
export class RegionWithI18nComponent extends CommonFormComponent<RegionWithI18n, RegionI18n> implements AfterViewInit {

  foreignKeyFieldName = "regionId";
  listRoute= "region-table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: RegionTableRow) => `${row.id}` },
    { columnDef: 'name', header: 'Name', cell: (row: RegionTableRow) => `${row.name}` },
    { columnDef: 'country', header: 'Country', cell: (row: RegionTableRow) =>  `${row.country }` },
  ];

  public title = 'Region List';
  public entity = 'Region';


  public countryList: Country[];
  public countryMap = new Map<number, Country>();


  constructor(
   	private _countryService: CountryService,
    _languageService: LanguageService,
    _regionWithI18nService: RegionWithI18nService,
    _regionI18nService: RegionI18nService,
    _formBuilder: FormBuilder,
    _notificationService: NotificationService,
    _router: Router,
    _dialog: MatDialog) {

    super(_languageService, _regionWithI18nService, _regionI18nService, _notificationService, _formBuilder, _router, _dialog)
  }


  ngOnInit() {

    this.initForm();

    forkJoin([
this._countryService.getAll(),
]).subscribe(([
countryList,
]) => {
      this.setCountryList(countryList);

      this.fetchDataAndLanguages();
    });

  }

  getTableRow(data: RegionWithI18n): RegionTableRow {
    let row = {} as RegionTableRow;
    row = Object.assign(data, row);
    row.country = this.countryMap.get(data.countryId)?.name;
    return row;
  }

  setCountryList = (data: Country[]) => {
    this.countryList = data;
    data.forEach((item) => this.countryMap.set(item.id, item));
  }

}
