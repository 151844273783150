import { Component, OnInit } from "@angular/core";
import { login } from "../util/APIUtils";
import { NotificationService } from "./../services/notification.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { getProfile } from "../util/APIUtils";

import { PROFILE, ACCESS_TOKEN } from "../constants";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  
  profileType = "U";

  form = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  get f() {
    return this.form.controls;
  }

  constructor(
    private _router: Router,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  submit() {
    const loginRequest = Object.assign({}, this.form.value);

    login(loginRequest)
      .then((response) => {
        localStorage.setItem(ACCESS_TOKEN, response.data.token);
        this._notificationService.success(
          "Success",
          "You're successfully logged in!"
        );
        getProfile().then((response) => {
          console.log("getProfile", response.data);
          localStorage.setItem(PROFILE, JSON.stringify(response.data));
          this._notificationService.updateProfile();
          this._notificationService.loggedIn();
          this._router.navigate(["wines-search-table"]);
        });
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
        );
        this._notificationService.error("Error", error.message);
      });
  }
}
