// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

import decode from 'jwt-decode';

import {
    ACCESS_TOKEN
} from "../constants";


@Injectable()
export class RoleGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        let success: boolean = this.auth.isAuthenticated();
        if (success) {

            // this will be passed from the route config
            // on the data property
            const expectedRole = route.data.expectedRole;
            const token = localStorage.getItem(ACCESS_TOKEN);

            // decode the token to get its payload
            const tokenPayload = decode(token);
            console.log("tokenPayload", tokenPayload);
            success = this.isAllowed(tokenPayload, expectedRole);
        }

        if (!success) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    isAllowed(tokenPayload, expectedRole): boolean {
        var res = tokenPayload["roles"].split(",");
        return res.includes(expectedRole);
    }

}