import { Component, OnInit } from "@angular/core";
import { updateProfile, changePassword } from "../util/APIUtils";
import { NotificationService } from "./../services/notification.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ACCESS_TOKEN, PROFILE } from "../constants";
import { ProducerService } from "../entities/producer/services/producer.service";
import { VendorService } from "../entities/vendor/services/vendor.service";
import { Account } from "../entities/account/account.model";
import { Producer } from "../entities/producer/producer.model";
import { Vendor } from "../entities/vendor/vendor.model";
import { LanguageService } from "../entities/language/services/language.service";
import { Language } from "../entities/language/language.model";
import { TinyEditorComponent } from "../util/tiny-editor/tiny-editor.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NamedQueryService } from "../services/named-query.service";


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {

  accountTypes: any = ["User", "Producer", "Vendor"];
  account: Account;
  profile: Producer | Vendor;
  languages: Language[];

  public typeMap = new Map([
    ['A', 'Admin'],
    ['U', 'User'],
    ['P', 'Producer'],
    ['V', 'Vendor']
  ]);

  form: FormGroup;
  formp: FormGroup;

  get f() {
    return this.form.controls;
  }

  get fp() {
    return this.formp.controls;
  }


  constructor(
    private _nqService: NamedQueryService,
    private _vendorService: VendorService,
    private _producerService: ProducerService,
    private _languageService: LanguageService,
    private _router: Router,
    public _dialog: MatDialog,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.loadLanguages();

    var group = {
      name: new FormControl("", [Validators.required]),
      type: new FormControl({ value: '', disabled: true }),
      language: new FormControl({ value: '', disabled: false }),
      email: new FormControl({ value: '', disabled: true })
    }
    this.form = new FormGroup(group);

    this.account = JSON.parse(localStorage.getItem(PROFILE));
    const account = this.account;
    this.form.controls.name.setValue(account.name);
    this.form.controls.type.setValue(this.typeMap.get(account.type));
    this.form.controls.email.setValue(account.email);
    this.form.controls.language.setValue(account.language);

    if (account.type == 'P') {
      this._nqService.getNQSingle(["producer", "findByAccountId", account.id]).subscribe((profile) => {
        this.profile = profile;
        group["pname"] = new FormControl("", [Validators.required]);
        group["pdescription"] = new FormControl("", []);
        this.form.controls.pname.setValue(this.profile.name);
        this.form.controls.pdescription.setValue(this.profile.description);
        this.form = new FormGroup(group);
      });
    } else if (account.type == 'V') {
      this._nqService.getNQSingle(["vendor", "findByAccountId", account.id]).subscribe((profile) => {
        this.profile = profile;
        group["pname"] = new FormControl("", [Validators.required]);
        group["pdescription"] = new FormControl("", []);
        this.form.controls.pname.setValue(this.profile.name);
        this.form.controls.pdescription.setValue(this.profile.description);
        this.form = new FormGroup(group);
      });
    }


    this.formp = new FormGroup({
      email: new FormControl({ value: '', disabled: true }),
      oldpassword: new FormControl("", [
        Validators.required
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
      password1: new FormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
    });


  }

  loadLanguages() {
    this._languageService.getAll().subscribe((data) => {
      this.languages = data;
    });
  }

  updateProfile() {

    var accountRequest: Account = this.pick(this.form.value, "id", "name", "language") as Account;
    updateProfile(accountRequest)
      .then((response) => {
        localStorage.setItem(PROFILE, JSON.stringify(response.data));
        this._notificationService.updateProfile();
        this._notificationService.success(
          "Success",
          "Profile has been successfully updated!"
        );
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
        );
        this._notificationService.error("Error", error.message);
      });

    //se c'è un profilo lo aggiorno
    if (!this.profile) return;

    this.profile.name = this.form.controls.pname.value;
    this.profile.description = this.form.controls.pdescription.value;

    if (this.account.type == 'P') {
      this._producerService.update(this.profile, [this.profile.id]).subscribe(() =>
        this._notificationService.success("Profile Update", "Your profile has been updated!")
      );
    } else if (this.account.type == 'V') {
      this._vendorService.update(this.profile, [this.profile.id]).subscribe(() =>
        this._notificationService.success("Profile Update", "Your profile has been updated!")
      );
    }
  }


  updatePassword() {
    const formValues = Object.assign({}, this.formp.value);

    if (formValues.password != formValues.password1) {
      this._notificationService.error("Passwords Mismatch", "Please check your passwords fields");
      return;
    }

    var request = {};
    request["oldPassword"] = formValues.oldpassword;
    request["newPassword"] = formValues.password;

    changePassword(request)
      .then((response) => {
        if (response.success) {
          this._notificationService.success(
            "Success",
            "Password has been modified!"
          );
        } else {
          this._notificationService.error("Error", response.message);
        }
      });
  }


  pick(obj, ...keys) {
    const result = {};
    for (const key of keys)
      result[key] = obj[key];
    return result;
  }


  initEditor(field, maxLength: number) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data["maxLength"] = maxLength;
    dialogConfig.data["title"] = field;
    dialogConfig.data["content"] = this.form.controls[field].value;
    let dialog = this._dialog.open(TinyEditorComponent, dialogConfig);
    dialog.afterClosed().subscribe(content => {
      if (content) {
        this.form.controls[field].setValue(content);
        this.form.controls[field].markAsDirty();
      }
    });
  }

}
