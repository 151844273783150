
// Modules imports
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DataStorage } from 'src/app/services/data-storage.provider';
import { NotificationService } from 'src/app/services/notification.service';
import { Wine } from '../wine.model';
import { WineService } from '../services/wine.service';



@Component({
  selector: 'app-wine-table',
  templateUrl: './wine-table.component.html',
  styleUrls: ['./wine-table.component.css']
})
export class WineTableComponent implements AfterViewInit {

  public filterText: string = null;

  title = "Wine Table";

  /** Table columns */
  displayedColumnsDef = [
    { columnDef: 'id', header: 'Id', cell: (row: Wine) => `${row.id ? row.id : ""}` },
    { columnDef: 'name', header: 'Name', cell: (row: Wine) => `${row.name ? row.name : ""}` },
    { columnDef: 'description', header: 'Description', cell: (row: Wine) => `${row.description ? row.description : ""}` },
    { columnDef: 'producerId', header: 'ProducerId', cell: (row: Wine) => `${row.producerId ? row.producerId : ""}` },
    { columnDef: 'colourId', header: 'ColourId', cell: (row: Wine) => `${row.colourId ? row.colourId : ""}` },
    { columnDef: 'effervescenceId', header: 'EffervescenceId', cell: (row: Wine) => `${row.effervescenceId ? row.effervescenceId : ""}` },
    { columnDef: 'drynessId', header: 'DrynessId', cell: (row: Wine) => `${row.drynessId ? row.drynessId : ""}` },
    { columnDef: 'fortified', header: 'Fortified', cell: (row: Wine) => `${row.fortified ? row.fortified : ""}` },
    { columnDef: 'degree', header: 'Degree', cell: (row: Wine) => `${row.degree ? row.degree : ""}` },
    { columnDef: 'regionId', header: 'RegionId', cell: (row: Wine) => `${row.regionId ? row.regionId : ""}` },
    { columnDef: 'countryId', header: 'CountryId', cell: (row: Wine) => `${row.countryId ? row.countryId : ""}` },
    { columnDef: 'appellationId', header: 'AppellationId', cell: (row: Wine) => `${row.appellationId ? row.appellationId : ""}` },
    { columnDef: 'authorId', header: 'AuthorId', cell: (row: Wine) => `${row.authorId ? row.authorId : ""}` },
    { columnDef: 'approved', header: 'Approved', cell: (row: Wine) => `${row.approved ? row.approved : ""}` },
    { columnDef: 'vintage', header: 'Vintage', cell: (row: Wine) => `${row.vintage ? row.vintage : ""}` },
  ];

displayedColumns = [
  "id",
  "name",
  "description",
  "producerId",
  "oenologistId",
  "colourId",
  "effervescenceId",
  "drynessId",
  "fortified",
  "degree",
  "regionId",
  "countryId",
  "appellationId",
  "authorId",
  "approved",
  "vintage",
];

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


 constructor(
    public _notificationService: NotificationService,
    private _wineService: WineService,
    private _data: DataStorage,
    public _router: Router,
    public _dialog: MatDialog) {

  }

  ngAfterViewInit() {

    this._wineService.getAll().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter() {
    const filter = this.filterText;
    if (filter.trim().length > 0 && filter.trim().length < 3) return;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterText = null;
    this.dataSource.filter = null;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewItem(row) {
    this._data.data = {
      editable: false,
      lockable: true
    }
    this._router.navigate(["wine-form", row.id]);
  }

  editItem(row) {
    this._data.data = {
      editable: true,
      lockable: false
    }
    this._router.navigate(["wine-form", row.id]);
  }



}
